/// <reference types="web-bluetooth" />
import { ref } from "vue";
import { createToast } from 'mosha-vue-toastify';
import { $database } from "@/jsstore_con";
import { CellRecord, IScanResult, ScanResult, ScanResultH, ScanResultS_lte, ScanResultS_5GNSA, ScanResultS_5GSA, ScanResultS_noService, ScanGPS, ScanResultS_wcdma, OperatoriTel, ScanResultS, ScanResultWiFi, wifiResult, blueResult, ScanResultBluetooth, ActiveCell, ActiveScan } from "./CellRecord";
import { b85encode, downloadBlob, } from "@/ts/fileutils";
import { Log } from "./Log";
import { BleClient, BleDevice, } from '@capacitor-community/bluetooth-le';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { Share } from '@capacitor/share';

const COMMAND_SERVICE_ID = '3cfee5c8-d5cf-41d2-8b10-23c5503c4fe5';
const COMMAND_SEND_CHARA = '00000000-0000-1000-8000-00805f9b34fb';
const COMMAND_FEEDBACK_CHARA = '00000001-0000-1000-8000-00805f9b34fb';
const SCAN_RESULT_A_CHARA = '00000002-0000-1000-8000-00805f9b34fb'
const SCAN_RESULT_B_CHARA = '00000004-0000-1000-8000-00805f9b34fb'
const SCAN_FEEDBACK_CHARA = '00000003-0000-1000-8000-00805f9b34fb'

/*
      const fileManagerCommandFeedbackChara = await fileManagerService.getCharacteristic('00000000-0000-1000-8000-00805f9b34fb');
      const fileManagerReceiverChara = await fileManagerService.getCharacteristic('00000001-0000-1000-8000-00805f9b34fb');
      const fileManagerChecksumChara = await fileManagerService.getCharacteristic('00000002-0000-1000-8000-00805f9b34fb');

*/

const FILEMANAGER_SERVICE_CHARA = '05674b70-ebda-11ec-8ea0-0242ac120002';
const FILEMANAGER_COMMAND_FB_CHARA = '00000000-0000-1000-8000-00805f9b34fb';
const FILEMANAGER_RECEIVER_CHARA = '00000001-0000-1000-8000-00805f9b34fb';
const FILEMANAGER_CHECKSUM_CHARA = '00000002-0000-1000-8000-00805f9b34fb';

class FileBuffer {
  totalParts: number;
  buffer: Array<Uint8Array>;
  fileName: string;
  currentChecksum = ""
  receivedParts = 0;

  constructor(fileName: string, totalParts: number) {
    this.fileName = fileName;
    this.totalParts = totalParts;
    this.buffer = new Array<Uint8Array>(totalParts);
  }

  public addPart(part: DataView) {
    if (this.receivedParts > this.totalParts) {
      throw "FileBuffer.addPart: all parts received";
    }
    this.buffer[this.receivedParts] = new Uint8Array(part.buffer) // don't merge or it would be too cpu & ram intensive
    this.receivedParts++;
  }

  public isComplete(): boolean {
    return this.receivedParts >= this.totalParts;
  }

  public getFileName(): string {
    return this.fileName;
  }

  public getBuffer(): Uint8Array[] {
    return this.buffer;
  }

  public async saveToFile(fileName: string) {
    if (!this.isComplete()) throw "FileBuffer.saveToFile: not complete";
    Log.info("FileBuffer.saveToFile: saving file " + fileName);

    const blob = new Blob(this.buffer, { type: "text/plain;charset=utf-8", });

    if (Capacitor.isNativePlatform()) {
      //console.warn("sharing method")


      let stringToWrite = "";

      await Filesystem.writeFile({
        path: "BTSTracker/" + fileName,
        data: "**cjson**\n[",
        recursive: true,
        directory: Directory.Documents,
        encoding: Encoding.ASCII
      });

      for (let i = 0; i < this.buffer.length; i++) {
        for (let j = 0; j < this.buffer[i].length; j++) {
          stringToWrite += this.buffer[i][j];
          // if is not the last element add ,
          if (j != this.buffer[i].length - 1) {
            stringToWrite += ",";
          }
        }

        if (i != this.buffer.length - 1) {
          stringToWrite += ",";
          await Filesystem.appendFile({
            data: stringToWrite,
            path: "BTSTracker/" + fileName,
            directory: Directory.Documents,
            encoding: Encoding.ASCII
          });
          stringToWrite = "";
        }
      }

      await Filesystem.appendFile({
        data: stringToWrite + "]\n",
        path: "BTSTracker/" + fileName,
        directory: Directory.Documents,
        encoding: Encoding.ASCII
      });

    } else {
      downloadBlob(blob, fileName);
    }
  }
}

class FoldersBuffer {
  totalParts: number;
  receivedParts = 0;
  buffer = "";
  constructor(totalParts: number) {
    this.totalParts = totalParts;
  }
  public addPart(part: string) {
    this.buffer += part;
    this.receivedParts++;
    if (this.receivedParts > this.totalParts) {
      throw "FileBuffer.addPart: all parts received";
    }
    //console.log(part);
  }
  public getBuffer(): string {
    return this.buffer;
  }
  public isComplete(): boolean {
    return this.receivedParts >= this.totalParts;
  }
}

class Command {
  command: Record<string, unknown>;
  commandResolverOk: (a: Record<string, unknown>) => void;
  constructor(command: Record<string, unknown>, commandResolverOk: (a: Record<string, unknown>) => void) {
    this.command = command;
    this.commandResolverOk = commandResolverOk;
  }
}

class ScanMap {
  power = 0;
  tec = "";
  coords: number[] = [];
  constructor(power: number, tec: string, long: number, lat: number) {
    this.power = power;
    this.tec = tec;
    this.coords[0] = long;
    this.coords[1] = lat;
  }
}

interface cellInfo {
  band: string;
  power: number;
}

export class searchCell {
  name = "";
  number = 0;
  type = "";
  constructor(name: string, number: number) {
    this.name = name;
    this.number = number;
  }
}

export class BTSTracker {

  // bts
  lastVersionF = "1.1.0.24142";
  connected = false;
  connecting = false;
  // battery 
  batteryPercentage = 0;
  mediaBatteria = 0;
  millivolts = 0;
  currVoltage = 0;
  usbConnected = 0;
  acok = 0;

  private static stringEncoder = new TextEncoder();
  private static stringDecoder = new TextDecoder("utf-8");

  isScanning = false;
  startScan = false;
  startScanSearch = true;
  tech = ['LTE', 'UMTS', 'GSM', ' '];
  technologies = [' '];
  modeS = '2';
  autoConnection = '5G';
  fallback = true;
  colonneDis = ['cell', 'freq', 'down_up', 'power', 'oper'];
  colonneActive = ['code', 'tec', 'arfcn', 'band', 'segnale', 'type', 'operator'];
  colonneWifi = ['mac', 'network', 'signal', 'encryp', 'freq'];
  colonneBlue = ['mac_', 'name', 'signal_', 'adv_type', 'remote'];

  lista = "1"; // visualizzazione files "0"-> icone; "1"-> lista
  fileBuffer: FileBuffer | undefined;
  folderBuffer: FoldersBuffer | undefined;
  // time and date da get_info
  deviceTime = "";
  deviceData = "";
  deviceDataTime = "";
  caricamentoSD = true;

  fileName = ""; // scan file
  NameFile = "";
  lastFileName = "";
  folder = "scan";
  timeStartScan = ""; // start time of scan
  gpsBTS4G = 0; // module 4G: not found = 0, connected = 1
  gpsBTS5G = 0; // module 5G: not found = 0, connected = 1
  restart = false;

  // list for map view 
  listCoordScansioni: ScanMap[] = [];
  listCoordPosizioni: number[][] = [];

  // cell results for AutoConnection
  cellLTE: ScanResultS_lte | undefined;
  cell5GNSA: ScanResultS_5GNSA | undefined;
  cell5GSA: ScanResultS_5GSA | undefined;
  cellNoSer: ScanResultS_noService | undefined;
  cellLTE2: ScanResultS_lte | undefined;
  cell5GNSA2: ScanResultS_5GNSA | undefined;
  cell5GSA2: ScanResultS_5GSA | undefined;
  cellNoSer2: ScanResultS_noService | undefined;
  cellwcdma: ScanResultS_wcdma | undefined;
  cellwcdma2: ScanResultS_wcdma | undefined;
  // cell results for LTE - UMTS - GSM
  cellHlte: ScanResultH | undefined;
  cellHumts: ScanResultH | undefined;
  cellHgsm: ScanResultH | undefined;
  cellH: ScanResultH | undefined;
  // wifi
  wifiScan: ScanResultWiFi | undefined;
  //bluetooth
  blueScan: ScanResultBluetooth | undefined;
  // cell 2G active set
  cellActive: ActiveCell | undefined;
  cellScanActive: ActiveScan | undefined;
  activeNONE = false;
  mcc_mncServingCell = "";

  errorWifi = false;
  errorBlue = false;

  closeConnected = false;
  closeAvailable = false;
  closeLTE = false;
  closeUMTS = false;
  closeGSM = false;
  closeWifi = false;
  closeBlue = false;
  close2g = false;

  appLocation: {
    latitude: number;
    longitude: number;
    accuracy: number;
    altitudeAccuracy: number | null | undefined;
    altitude: number | null;
    speed: number | null;
    heading: number | null;
  } | undefined = undefined;

  manuallyPosition = false;
  newPoint = false;
  deleteManuallyPosition = false; // quando viene eliminata la posizione manuale
  manuallyCoordinates: {
    latitude: number;
    longitude: number;
  } = { latitude: 0, longitude: 0 }; // latitude, longitude posizione settata manualmente
  dialogSetLocation = false;
  seeManualLocation = false;

  // file characteristics
  connectedDeviceId: BleDevice | undefined = undefined;

  fileBufferPromiseResolver: undefined | ((a: FileBuffer) => void);
  folderBufferPromiseResolver: undefined | ((a: FoldersBuffer) => void);

  // count of scan results
  scanResults = 0;
  scanResultsS = 0;
  scanResultsH = 0;
  netResultL = 0;
  netResultG = 0;
  netResultU = 0;
  timeLte = "";
  timeUmts = "";
  timeGsm = "";
  scanResultWifi = 0;
  scanResultBlue = 0;
  scanResultActive = 0;

  moreWifi = false;
  moreBlue = false;

  commands: Command[] = [];
  currentCommandObject: Command | undefined = undefined;
  availableAt = new Date().getTime();

  // gps
  tipo = 0; // type of gps --> se client = -1, se BTS = 1
  latitude = 0.0;
  longitude = 0.0;
  altitude = 0.0;
  accuracy = 0.0;
  speed = 0;
  geoLocationError = false;
  course = 0.0;
  link = "https://google.com/maps/@" + this.latitude + "," + this.longitude;
  antennaGPS = false;
  // sd card
  valid = 0;
  sdCardError = false;
  sdCardTotalSize = 0;
  sdCardUsedSize = 0;
  updateSD = false;
  // sim 
  numSim = 0;
  simH = "";
  simS1 = "";
  imsi1_old = "";
  simS2 = "";
  imsi2_old = "";
  opeSim1: OperatoriTel | undefined;
  opeSim2: OperatoriTel | undefined;
  opeSim0: OperatoriTel | undefined;
  checkimsi = true;
  pin1 = "";
  pin2 = "";
  // bts info
  serial = "";
  version = "";
  init = 0;
  bootSeconds = 0;
  perc = 0;
  resultInit = "";
  modA = 0;
  modB = 0;

  datetime = "";

  errorOta = "";
  updateStart = false;
  // state of cell results
  stateLTE = 0;
  stateNO = 0;
  state5GNSA = 0;
  state5GSA = 0;
  stateLTE2 = 0;
  stateNO2 = 0;
  state5GNSA2 = 0;
  state5GSA2 = 0;
  stateWcdma = 0;
  stateWcdma2 = 0;
  stateActive = 0;
  // slot of sim
  slot1 = false;
  slot2 = false;

  staticName = false;
  searchName = "";

  sendLocationToBtsTrackerTimeoutID: number | undefined;

  aops: string[] = [];
  cellList = "";
  mcc = "";
  mnc = "";
  lac = "";
  cellid = "";
  interestedCell: searchCell[] = [];
  singleSearch: searchCell | undefined;
  totalInterestedCell = 0;
  countCellB1 = 0;
  countCellB2 = 0;
  connectedCell = true;
  availableCell = true;
  wifi = true;
  blue = true; // scansioni bluetooth attive
  reloadFolder = false;
  checkAccuracy = false;

  lastPosition: GeolocationCoordinates | undefined;

  apnSettings = false;
  ftpSettings = false;
  apnLauched = false;
  ftpLauched = false;
  APNlist = "";
  apn1 = "";
  userApn1 = "";
  passApn1 = "";
  apn2 = "";
  userApn2 = "";
  passApn2 = "";
  ftp = "";
  userFtp = "";
  passFtp = "";
  staticInfo = false;
  enableapn = false;
  sceltaSim = '1';
  reportUpload = false;
  differentSim1 = false;
  differentSim2 = false;

  searchAPN = "";
  selectAPN1 = 0;
  selectAPN2 = 0;

  restart_hua = false;
  modem = 1;
  checkGeolocation = true;

  moduleA = "";
  moduleB = "";
  moduleWifi = -1;

  activeSet = false;
  check = false;
  readyForScanning = false;
  device: BleDevice | undefined;

  sito = "";

  constructor() {
    this.sito = document.location.href;
    const anyNav: any = navigator
    if ('wakeLock' in navigator) {
      try {
        anyNav["wakeLock"].request("screen");
      } catch (error) {
        console.error(error);
      }
    }

    setInterval(async () => {

      if (this.connected && this.connectedDeviceId && !this.currentCommandObject) {
        if (this.commands.length > 0) {
          this.currentCommandObject = this.commands.shift();
          try {
            const uintarr = BTSTracker.stringEncoder.encode(JSON.stringify(this.currentCommandObject?.command) + '\0');
            await BleClient.write(this.connectedDeviceId.deviceId, COMMAND_SERVICE_ID, COMMAND_SEND_CHARA, new DataView(uintarr.buffer, 0, uintarr.byteLength));
          } catch (error) {
            Log.error(error);
            Log.error(BTSTracker.stringEncoder.encode(JSON.stringify(this.currentCommandObject?.command) + '\0'));
          }
        } else {
          if (new Date().getTime() - this.availableAt >= 20000) {
            this.availableAt = new Date().getTime();
            setTimeout(() => {
              try {
                this.updateInfos().catch(err => {
                  Log.error(err);
                }).then(() => this.availableAt = new Date().getTime());
              } catch (error) {
                Log.error(error);
              }
            }, 500)
          }
        }
      }
    }, 150);
    this.deviceTime = "";
  }

  public saveScreen(): void {
    //console.log("save.2")
    const canvas = document.getElementsByTagName("canvas")[0];
    const img = canvas.toDataURL();
    //console.log("check file system", Capacitor.isPluginAvailable('Filesystem'));
    if (Capacitor.isNativePlatform()) {
      Filesystem.writeFile({
        path: `BTSTracker/${Date.now()}-screen.png`,
        data: img,
        recursive: true,
        directory: Directory.Documents,
      }).then((x) => {
        //console.log(x)
        createToast(
          {
            title: "Screenshot saved",
            description: "",
          },
          {
            type: 'success',
            showIcon: true,
            hideProgressBar: true,
          });
      });

    } else {
      _downloadFile(img, Date.now() + ".png", "");
      createToast(
        {
          title: "Screenshot saved",
          description: "",
        },
        {
          type: 'success',
          showIcon: true,
          hideProgressBar: true,
        });
    }


    //console.log(img);
  }


  async sendManualLocationToBtsTracker(manualLocation: { latitude: number, longitude: number }) {
    //console.log("update manual gps");
    await this.sendCommand("update_manual_gps", {
      "latitude": manualLocation.latitude,
      "longitude": manualLocation.longitude,
      "use": this.manuallyPosition,
    });
  }

  sendLocationToBtsTracker(geoLoc: {
    latitude: number;
    longitude: number;
    accuracy: number;
    altitudeAccuracy: number | null | undefined;
    altitude: number | null;
    speed: number | null;
    heading: number | null;
  }): void {
    // quando si apre il browser partono un sacco di richieste, per evitare un sacco di chiamate
    // metto un timer con un leggero ritardo. In questo modo le chiamate troppo ravvicinate vengono 
    this._sendLocationToBtsTracker(geoLoc);
  }

  _sendLocationToBtsTracker(geoLoc: {
    latitude: number;
    longitude: number;
    accuracy: number;
    altitudeAccuracy: number | null | undefined;
    altitude: number | null;
    speed: number | null;
    heading: number | null;
  }): void {

    /*if (typeof this.sendLocationToBtsTrackerTimeoutID === 'number') {
      clearTimeout(this.sendLocationToBtsTrackerTimeoutID);
    }*/


    this.sendLocationToBtsTrackerTimeoutID = setTimeout(() => {
      //console.log("update_gps");

      this.sendCommand("update_gps", {
        "latitude": geoLoc.latitude.toFixed(5),
        "longitude": geoLoc.longitude.toFixed(5),
        "accuracy": geoLoc.accuracy.toFixed(3),
        "speed": geoLoc.speed ? geoLoc.speed.toFixed(3) : null,
        "altitude": geoLoc.altitude ? geoLoc.altitude.toFixed(3) : null,
        "altitudeAccuracy": geoLoc.altitudeAccuracy ? geoLoc.altitudeAccuracy.toFixed(3) : null,
        "heading": geoLoc.heading ? geoLoc.heading.toFixed(3) : null
      });

      this.sendLocationToBtsTrackerTimeoutID = undefined;

    }, 5000);

  }

  public async createConnection() {
    if (this.connected || this.connectedDeviceId || this.connecting) return;
    try {
      this.connecting = true;

      this.commands = [];
      this.deviceTime = "";
      this.isScanning = false;
      this.fileBufferPromiseResolver = undefined;
      this.fileBuffer = undefined;
      this.scanResultsS = 0;
      this.scanResultsH = 0;
      this.folderBuffer = undefined;
      this.folderBufferPromiseResolver = undefined;
      this.currentCommandObject = undefined;


      //console.log("Requesting Bluetooth Device...");

      await BleClient.initialize();

      const device = await BleClient.requestDevice({
        services: [COMMAND_SERVICE_ID, FILEMANAGER_SERVICE_CHARA],
        optionalServices: [COMMAND_SERVICE_ID, FILEMANAGER_SERVICE_CHARA]
      });

      if (!device) throw "No device selected";


      await BleClient.connect(device.deviceId, () => this.onDisconnect());

      await BleClient.startNotifications(device.deviceId, COMMAND_SERVICE_ID, COMMAND_FEEDBACK_CHARA, (result) => {
        $BTSTracker.value.handleCommandFeedback(result);
      });
      await BleClient.startNotifications(device.deviceId, COMMAND_SERVICE_ID, SCAN_RESULT_A_CHARA, (result) => {
        $BTSTracker.value.handleScanResultA(result);
      });
      await BleClient.startNotifications(device.deviceId, COMMAND_SERVICE_ID, SCAN_RESULT_B_CHARA, (result) => {
        $BTSTracker.value.handleScanResultB(result);
      });

      await BleClient.startNotifications(device.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_RECEIVER_CHARA, (result) => {
        $BTSTracker.value.handleFileManagerReceiver(result);
      });

      await BleClient.startNotifications(device.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_CHECKSUM_CHARA, (result) => {
        $BTSTracker.value.handleChecksumFeedback(result);
      });

      this.connectedDeviceId = device;
      this.connecting = false;
      this.connected = true;

      await this.sendCommand("set_time", { "time": Math.trunc(Date.now()) });

      const resp = await this.sendCommand("get_bts_info", {});
      this.gpsBTS4G = 0; // module 4G: not found = 0, connected = 1
      this.gpsBTS5G = 0; // module 5G: not found = 0, connected = 1

      if (resp.ok) {
        const tmp = (resp.msg as string).split(";");
        this.serial = tmp[0];
        this.version = tmp[1];
      }

      this.updateInfos();

      this.apn_ftp();

      //console.log("connected");
      if (this.isScanning) {
        this.interestedCell = [];
        if (this.cellList && this.cellList.length > 0) {
          await this.sendCommand("set_cell", { "cell": this.cellList });
          this.cellList = this.cellList.replaceAll(" ", "\n");
          const cell = this.cellList.split("\n");
          for (let i = 0; i < cell.length; i++) {
            if (cell[i].length > 6) {
              this.interestedCell.push(new searchCell(cell[i], 0));
            }
          }
        }
      }
      this.update();
    } catch (e) {
      Log.error(e);
      await this.onDisconnect(false);
    }
  }

  private async update() {
    if (this.version.indexOf(this.lastVersionF) < 0) {
      createToast(
        {
          title: "Update available",
          description: "A new firmware update is available, see the guide",
        }, {
        type: 'warning',
        showIcon: true,
        hideProgressBar: true,
      }
      );
    }

  }

  private async onDisconnect(showError = true) {
    //console.log("DISCONNECTED");
    $BTSTracker.value.connected = false;
    $BTSTracker.value.connecting = false;

    if (showError) {
      createToast(
        {
          title: "Connection Interrupted",
          description: "Bluetooth connection died",
        },
        {
          type: 'danger',
          showIcon: true,
          hideProgressBar: true,
        });
    }
    if ($BTSTracker.value.connectedDeviceId) {
      try {
        await BleClient.disconnect($BTSTracker.value.connectedDeviceId.deviceId);
      } catch (error) {
        Log.error(error);
      }
    }

    $BTSTracker.value.connectedDeviceId = undefined;
  }

  async coordinates() {

    const coordinates = await Geolocation.getCurrentPosition();
    //console.log(coordinates);
    //console.log(this.manuallyPosition);

    if (this.gpsBTS4G == 0 && this.gpsBTS5G == 0) {
      if (this.manuallyPosition == false || this.deleteManuallyPosition == true) { // se la posisione è in base al browser
        this.tipo = -1;
        this.latitude = (Number)(coordinates.coords.latitude.toFixed(5));
        this.longitude = (Number)(coordinates.coords.longitude.toFixed(5));
        this.accuracy = (Number)(coordinates.coords.accuracy.toFixed(3));
        if (coordinates.coords.speed != undefined) {
          this.speed = coordinates.coords.speed;
        }
        $BTSTracker.value.appLocation = coordinates.coords;
        this.manuallyCoordinates.latitude = (Number)(this.latitude.toFixed(5));
        this.manuallyCoordinates.longitude = (Number)(this.longitude.toFixed(5));
      }
    } else {
      this.manuallyPosition = false;
    }
    if (this.manuallyPosition && this.gpsBTS4G == 0 && this.gpsBTS5G == 0) { // se la posizione è manuale
      //console.log("MANUAL");
      this.latitude = (Number)(this.manuallyCoordinates.latitude.toFixed(6));
      this.longitude = (Number)(this.manuallyCoordinates.longitude.toFixed(6));
    }

    if (!$BTSTracker.value.isConnected()) {
      this.geoLocationError = false; // altrimenti il dialog di avviso rimane aperto.
      return;
    }
    if (this.isConnected()) {
      this.sendLocationToBtsTracker(coordinates.coords);
      this.sendManualLocationToBtsTracker(this.manuallyCoordinates);
    }

    if (this.newPoint) {
      this.newPoint = false;
    }

    this.deleteManuallyPosition = false;
  }

  interestedInfo(cellCode: string) {
    //console.log(cellCode);
    if (cellCode.startsWith(this.mcc.toString())
      || cellCode.indexOf("-" + this.mnc + "-") > 0
      || cellCode.indexOf("-" + this.lac + "-") > 0
      || cellCode.indexOf("-" + this.cellid) > 0) {
      return true;
    } else {
      return false;
    }
  }

  async handleScanResultA(result: DataView) {
    if (this.readyForScanning) {
      this.perc = 100;
      this.init = 1;
    }
    if (this.isScanning == true) {
      const location = [(Number)($BTSTracker.value.longitude.toFixed(5)), (Number)($BTSTracker.value.latitude.toFixed(5))];
      const l = this.listCoordPosizioni.length;
      if (l == 0) {
        this.listCoordPosizioni.push(location);
      } else if (this.listCoordPosizioni.length >= 1) {
        if ((Number)($BTSTracker.value.longitude.toFixed(5)) != this.listCoordPosizioni[l - 1][0]) {
          this.listCoordPosizioni.push(location);
        } else if ((Number)($BTSTracker.value.latitude.toFixed(5)) != this.listCoordPosizioni[l - 1][1]) {
          this.listCoordPosizioni.push(location);
        }
      } else {
        this.listCoordPosizioni.push(location);
      }
      if (l == 2 && this.listCoordPosizioni[1][0] - this.listCoordPosizioni[0][0] == this.listCoordPosizioni[1][0]) {
        this.listCoordPosizioni.shift();
      }
    }
    try {
      if ($BTSTracker.value.connectedDeviceId) {
        const str = BTSTracker.stringDecoder.decode(result);
        try {
          if (str.startsWith("{")) {
            const jsonData = JSON.parse(str);
            //console.log(jsonData);
            const modem = (Number)(jsonData.ind);
            const scannum = (Number)(jsonData.num ?? jsonData.surveyNum); // rinominato dalla versione 
            let atCommand = (String)(jsonData.d ?? jsonData.data);
            const parte = (Number)(jsonData.tp);
            atCommand = atCommand.replaceAll("wifi;start;;", "");
            atCommand = atCommand.replaceAll("bt;start;;", "");
            atCommand = atCommand.replace("[BLE Device] GAP scan start;;", "");
            //console.log(atCommand);
            if (modem == 0) {
              atCommand = atCommand.replaceAll("\r;", ";");
              atCommand = atCommand.replaceAll("\r", "");
              if (atCommand.indexOf("wifi") >= 0 || parte == 0) {                // SCANSIONI WIFI 
                const result = wifiResult.parseWifi(atCommand, scannum);
                if (atCommand.indexOf("wifi") >= 0) {
                  if (result == undefined) {
                    this.errorWifi = true;
                    this.scanResultWifi = scannum;
                  } else if (result) {
                    this.errorWifi = false;
                    if (parte == 0) {
                      this.moreWifi = false;
                      this.wifiScan = result;
                      this.listCoordScansioni.push(new ScanMap((Number)(this.wifiScan.network[0].signal), "wifi", this.longitude, this.latitude));
                      const event = new Event('on-scan-result-coming');
                      document.dispatchEvent(event);
                    } else {
                      for (let i = 0; i < result.network.length; i++) {
                        this.wifiScan?.addScan(result.network[i].name, result.network[i].signal, result.network[i].encryp, result.network[i].mac, result.network[i].frequency, result.ScanNumber);
                      }
                    }
                    this.scanResultWifi = scannum;
                    this.wifiScan?.network.sort((x, y) => y.signal - x.signal);

                  }
                }
                // console.log(this.wifiScan?.network);
              }
              if (atCommand.indexOf("BT") >= 0 || atCommand.indexOf("bt") >= 0 || parte == 0 || atCommand.indexOf("bt;start;;BT,") >= 0) {// SCANSIONI BLUETOOTH
                //console.log(atCommand);
                const result = blueResult.parseBlue(atCommand, scannum);
                if (atCommand.indexOf("BT") >= 0 || atCommand.indexOf("bt") >= 0) {
                  if (result == undefined) {
                    this.errorBlue = true;
                    this.scanResultBlue = scannum;
                  } else {
                    this.errorBlue = false;
                    if (parte == 0) {
                      this.moreBlue = false;
                      this.blueScan = result;
                      this.listCoordScansioni.push(new ScanMap((Number)(this.blueScan.network[0].signal), "blue", this.longitude, this.latitude));
                      const event = new Event('on-scan-result-coming');
                      document.dispatchEvent(event);
                    } else {
                      for (let i = 0; i < result.network.length; i++) {
                        this.blueScan?.addScan(result.network[i].name, result.network[i].signal, result.network[i].mac, result.network[i].adv_type_, result.network[i].remote_add, result.ScanNumber);
                      }
                    }
                    this.scanResultBlue = scannum;
                    this.blueScan?.network.sort((x, y) => y.signal - x.signal);
                  }
                }
              }
            } else {
              // per le risposte dei moduli
              const atCommand = (String)(jsonData.d ?? jsonData.data);
              const scannum = (Number)(jsonData.num ?? jsonData.surveyNum); // rinominato dalla versione 
              const modem = (Number)(jsonData.ind);
              // SE MODULO HUAWEI versione nuova [moduli 1,2,3,4,5]
              if (JSON.stringify(jsonData).indexOf("ind") >= 0 && (modem == 1 || modem == 3)) {
                this.modem = modem;
                const tp = (String)(jsonData.pt ?? jsonData.tp);
                const type = (String)(tp.charAt(0));
                let i = 1;
                let p = "";
                for (i = 1; i < tp.length; i++) {
                  p += tp.charAt(i);
                }
                const part = (Number)(p);
                const location = [this.longitude, this.latitude];
                let result;
                if (modem == 1) { // H 1,2,3
                  result = ScanResult.parseAtCommandHauwei(atCommand, scannum, part, type, location);
                } else if (modem == 3) { // H 4,5
                  result = ScanResult.parse4GModule(atCommand, scannum, part, type, location);
                }
                if (result instanceof ScanResultH) {
                  this.restart_hua = false;
                  if (type.indexOf("G") >= 0) {
                    if (part == 0) {
                      this.cellHgsm = result;
                      this.cellHgsm.time = new Date();
                      this.netResultG++;
                      if (this.isScanning == true && modem == 1) {
                        this.listCoordScansioni.push(new ScanMap((Number)(this.cellHgsm.Cells[0].power), this.cellHgsm.Technology, (Number)(this.cellHgsm.location[0].toFixed(5)), (Number)(this.cellHgsm.location[1].toFixed(5))));
                        const event = new Event('on-scan-result-coming');
                        document.dispatchEvent(event);
                      }
                    } else {
                      let i = 0;
                      for (i = 0; i < result.Cells.length; i++) {
                        this.cellHgsm?.Cells.push(
                          {
                            cellCode: result.Cells[i].cellCode,
                            band: result.Cells[i].band,
                            arfcn: result.Cells[i].arfcn,
                            power: result.Cells[i].power,
                            operatore: result.Cells[i].operatore,
                            img: result.Cells[i].img,
                            down_frequency: result.Cells[i].down_frequency,
                          }
                        );
                      }
                      this.cellHgsm?.Cells.sort((x, y) => y.power - x.power);
                    }
                  } else if (type.indexOf("U") >= 0) {
                    if (part == 0) {
                      this.cellHumts = result;
                      this.netResultU++;
                      this.cellHumts.time = new Date();
                      if (this.isScanning == true && modem == 1) {
                        this.listCoordScansioni.push(new ScanMap((Number)(this.cellHumts.Cells[0].power), this.cellHumts.Technology, (Number)(this.cellHumts.location[0].toFixed(5)), (Number)(this.cellHumts.location[1].toFixed(5))));
                        const event = new Event('on-scan-result-coming');
                        document.dispatchEvent(event);
                      }
                    } else {
                      let i = 0;
                      for (i = 0; i < result.Cells.length; i++) {
                        this.cellHumts?.Cells.push(
                          {
                            cellCode: result.Cells[i].cellCode,
                            band: result.Cells[i].band,
                            arfcn: result.Cells[i].arfcn,
                            power: result.Cells[i].power,
                            operatore: result.Cells[i].operatore,
                            img: result.Cells[i].img,
                            down_frequency: result.Cells[i].down_frequency,
                          }
                        );
                      }
                      this.cellHumts?.Cells.sort((x, y) => y.power - x.power);
                    }
                  } else if (type.indexOf("L") >= 0) {
                    if (part == 0) {
                      this.cellHlte = result;
                      this.cellHlte.time = new Date();
                      this.netResultL++;
                      if (this.isScanning == true && modem == 1) {
                        this.listCoordScansioni.push(new ScanMap((Number)(this.cellHlte.Cells[0].power), this.cellHlte.Technology, (Number)(this.cellHlte.location[0].toFixed(5)), (Number)(this.cellHlte.location[1].toFixed(5))));
                        const event = new Event('on-scan-result-coming');
                        document.dispatchEvent(event);
                      }
                    } else {
                      let i = 0;
                      for (i = 0; i < result.Cells.length; i++) {
                        this.cellHlte?.Cells.push(
                          {
                            cellCode: result.Cells[i].cellCode,
                            band: result.Cells[i].band,
                            arfcn: result.Cells[i].arfcn,
                            power: result.Cells[i].power,
                            operatore: result.Cells[i].operatore,
                            img: result.Cells[i].img,
                            down_frequency: result.Cells[i].down_frequency,
                          }
                        );
                      }
                      this.cellHlte?.Cells.sort((x, y) => y.power - x.power);
                    }
                  }
                  this.scanResultsH = result.ScanNumber;
                  this.cellH = result;
                  for (let j = 0; j < this.cellH.Cells.length; j++) {
                    //console.log(this.cellH.Cells[j].cellCode);
                    for (let x = 0; x < this.interestedCell.length; x++) {
                      //console.log(this.interestedCell[x]);
                      if (this.cellH.Cells[j].cellCode == this.interestedCell[x].name) { //|| this.interestedInfo(this.cellH.Cells[j].cellCode
                        //console.log(this.cellH.Cells[j].cellCode);
                        this.interestedCell[x].number++;
                        if (type == 'L') {
                          this.interestedCell[x].type = 'LTE';
                        } else if (type == 'G') {
                          this.interestedCell[x].type = 'GSM';
                        } else if (type == 'U') {
                          this.interestedCell[x].type = 'UMTS';
                        }
                      }
                    }
                  }
                } else if (atCommand.indexOf("END AOPS") >= 0) {
                  if (type.indexOf("G") >= 0 && this.cellHgsm != undefined) {
                    this.cellHgsm?.Cells.sort((x, y) => y.power - x.power);
                    this.listCoordScansioni.push(new ScanMap((Number)(this.cellHgsm?.Cells[0].power), this.cellHgsm.Technology, (Number)(this.cellHgsm?.location[0].toFixed(5)), (Number)(this.cellHgsm?.location[1].toFixed(5))));
                    const event = new Event('on-scan-result-coming');
                    document.dispatchEvent(event);
                  } else if (type.indexOf("U") >= 0 && this.cellHumts != undefined) {
                    this.cellHumts?.Cells.sort((x, y) => y.power - x.power);
                    this.listCoordScansioni.push(new ScanMap((Number)(this.cellHumts?.Cells[0].power), this.cellHumts.Technology, (Number)(this.cellHumts?.location[0].toFixed(5)), (Number)(this.cellHumts?.location[1].toFixed(5))));
                    const event = new Event('on-scan-result-coming');
                    document.dispatchEvent(event);
                  } else if (type.indexOf("L") >= 0 && this.cellHlte != undefined) {
                    this.cellHlte?.Cells.sort((x, y) => y.power - x.power);
                    this.listCoordScansioni.push(new ScanMap((Number)(this.cellHlte?.Cells[0].power), this.cellHlte.Technology, (Number)(this.cellHlte?.location[0].toFixed(5)), (Number)(this.cellHlte?.location[1].toFixed(5))));
                    const event = new Event('on-scan-result-coming');
                    document.dispatchEvent(event);
                  }

                } else if (result instanceof ScanGPS) {
                  this.latitude = result.latitude;
                  this.longitude = result.longitude;
                  this.altitude = Number(result.altitude);
                  this.antennaGPS = true;
                } else if (result instanceof ActiveScan) {
                  this.stateActive = 1;
                  if (part == 0) {
                    this.cellScanActive = result;
                    if (this.cellScanActive.Cell.length == 0) {
                      this.activeNONE = true;
                    } else {
                      this.activeNONE = false;
                    }
                    this.scanResultActive++;
                    this.listCoordScansioni.push(new ScanMap((Number)(this.scanResultActive), "active", this.longitude, this.latitude));
                    const event = new Event('on-scan-result-coming');
                    document.dispatchEvent(event);
                  } else {
                    for (let i = 0; i < result.Cell.length; i++) {
                      this.cellScanActive?.addCell(result.Cell[i]);
                    }
                  }
                  if (this.cellScanActive != null) {
                    for (let j = 0; j < this.cellScanActive?.Cell.length; j++) {
                      for (let x = 0; x < this.interestedCell.length; x++) {
                        if (this.cellScanActive.Cell[j].cellcode == this.interestedCell[x].name) { //|| this.interestedInfo(this.cellScanActive.Cell[j].cellcode.toString())
                          this.interestedCell[x].type = '2G';
                        }
                      }
                    }
                  }
                }
              }
              let conta = 0;
              for (let i = 0; i < this.interestedCell.length; i++) {
                conta = conta + this.interestedCell[i].number;
              }
              this.totalInterestedCell = conta;

            }
            this.scanResults = this.scanResultsH + this.scanResultsS + this.scanResultWifi + this.scanResultBlue + this.scanResultActive;
          }
          else {
            const record = CellRecord.fromCsv(str);
            if (record) {
              record.fileName = $BTSTracker.value.fileName;
              $BTSTracker.value.scanResultsS++;
              $BTSTracker.value.scanResultsH++;
              const event = new CustomEvent('new-cell', { detail: record });
              window.dispatchEvent(event);
            }
          }

        } catch (e) {
          //console.log("scan Feedback Chara A");
          const rsp = new Int32Array([1]).buffer;
          await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, COMMAND_SERVICE_ID, SCAN_FEEDBACK_CHARA, new DataView(rsp, 0, rsp.byteLength));
          /*//console.log($BTSTracker.value.scanFeedbackChara);*/
        }
        $BTSTracker.value.availableAt = new Date().getTime();
      }

    } catch (e) {
      //console.log(e);
    }
  }

  async handleScanResultB(result: DataView) {
    if (this.readyForScanning) {
      this.perc = 100;
      this.init = 1;
    }
    if (this.isScanning && this.startScanSearch == true) {
      if (this.cellList.length != 0) {
        await this.sendCommand("set_cell", { "cell": this.cellList });
        this.cellList = this.cellList.replaceAll(" ", "\n");
        const cell = this.cellList.split("\n");
        for (let i = 0; i < cell.length; i++) {
          this.interestedCell.push(new searchCell(cell[i], 0));
        }
      }
      this.startScanSearch = false;
    }
    try {
      if ($BTSTracker.value.connectedDeviceId) {
        const str = BTSTracker.stringDecoder.decode(result);
        try {
          if (str.startsWith("{")) {
            const jsonData = JSON.parse(str);
            //console.log(jsonData);
            // per inizializzazione veloce e immediata
            if (JSON.stringify(jsonData).indexOf("check") >= 0) {
              $BTSTracker.value.modA = Number(jsonData.mod1);
              $BTSTracker.value.modB = Number(jsonData.mod2);
              $BTSTracker.value.perc = Number(jsonData.perc);
              $BTSTracker.value.moduleWifi = Number(jsonData.wifi);
              if ($BTSTracker.value.moduleWifi == 1 && $BTSTracker.value.isScanning == false) {
                $BTSTracker.value.wifi = true;
                $BTSTracker.value.blue = true;
              } else if ($BTSTracker.value.moduleWifi == 0 && $BTSTracker.value.isScanning == false) {
                $BTSTracker.value.wifi = true; $BTSTracker.value.blue = false;
              }
            } else if (JSON.stringify(jsonData).indexOf("count") >= 0) {
              //console.log(jsonData);
              $BTSTracker.value.numSim = (Number)(jsonData.count);
              $BTSTracker.value.simH = (jsonData.a1);
              $BTSTracker.value.simS1 = (jsonData.b1);
              $BTSTracker.value.simS2 = (jsonData.b2);
              this.setOperatori();
              $BTSTracker.value.pin1 = (jsonData.p1);
              $BTSTracker.value.pin2 = (jsonData.p2);
              if ($BTSTracker.value.pin1.indexOf("/") >= 0) {
                $BTSTracker.value.pin1 = "";
              }
              if ($BTSTracker.value.pin2.indexOf("/") >= 0) {
                $BTSTracker.value.pin2 = "";
              }
              if ($BTSTracker.value.simS1.length > 0) {
                $BTSTracker.value.slot1 = true;
              } else {
                $BTSTracker.value.slot1 = false;
              }
              if ($BTSTracker.value.simS2.length > 0) {
                $BTSTracker.value.slot2 = true;
              } else {
                $BTSTracker.value.slot2 = false;
              }
              if ($BTSTracker.value.simS1.length == 0) {
                //console.log("non c'è 1");
                this.imsi1_old = "";
                this.apn1 = "";
                this.userApn1 = "";
                this.passApn1 = "";
              }
              if ($BTSTracker.value.simS2.length == 0) {
                //console.log("non c'è 2");
                this.imsi2_old = "";
                this.apn2 = "";
                this.userApn2 = "";
                this.passApn2 = "";
              }
              if (this.simS1.length == 0 && this.simS2.length == 0) {
                this.connectedCell = false;
              }
              if (this.check == false) {
                if (this.simH.length > 0 && !this.isScanning) {
                  this.activeSet = true; this.availableCell = false;
                  this.check = true;
                } else if (!this.isScanning) {
                  this.activeSet = false; this.availableCell = true;
                }
              }

              this.perc = 100;
              this.init = 1;
              this.readyForScanning = true;
            } else {
              // per le risposte dei moduli
              const atCommand = (String)(jsonData.d ?? jsonData.data);
              const scannum = (Number)(jsonData.num ?? jsonData.surveyNum); // rinominato dalla versione 
              const modem = (Number)(jsonData.ind);

              // [modem B,C,D]
              let part = "00";
              part = (String)(jsonData.pt ?? jsonData.tp);
              const location = [this.longitude, this.latitude];
              const scanResult = ScanResult.parseFromAtCommand(atCommand, scannum, location);

              if (scanResult) {
                if (scanResult instanceof ScanResultS_5GNSA) {
                  this.scanResultsS = scanResult.ScanNumber + 1;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cell5GNSA = scanResult;
                    this.state5GNSA = 1;
                    this.state5GSA = 0;
                    this.stateLTE = 0;
                    this.stateNO = 0;
                    this.stateWcdma = 0;
                    this.countCellB1++;
                  } else {
                    this.cell5GNSA2 = scanResult;
                    this.state5GNSA2 = 1;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 0;
                    this.stateNO2 = 0;
                    this.countCellB2++;
                  }
                  this.checkCell(scanResult.lte.cellcode, 'nsa', scanResult.lte.frequencyBand, scanResult.lte.rsrp);
                  if (scanResult.lte.cellcodeS != undefined) {
                    this.checkCell(scanResult.lte.cellcodeS, 'nsa', scanResult.lte.frequencyBand, scanResult.lte.rsrp);
                  }
                } else if (scanResult instanceof ScanResultS_5GSA) {
                  this.scanResultsS = scanResult.ScanNumber + 1;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cell5GSA = scanResult;
                    this.state5GSA = 1;
                    this.state5GNSA = 0;
                    this.stateLTE = 0;
                    this.stateWcdma = 0;
                    this.stateNO = 0;
                    this.countCellB1++;
                  } else {
                    this.cell5GSA2 = scanResult;
                    this.state5GNSA2 = 0;
                    this.state5GSA2 = 1;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 0;
                    this.stateNO2 = 0;
                    this.countCellB2++;
                  }
                  this.checkCell(scanResult.cellcode, 'sa', scanResult.frequencyBand, scanResult.rsrp);
                } else if (scanResult instanceof ScanResultS_lte) {
                  this.scanResultsS = scanResult.ScanNumber + 1;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cellLTE = scanResult;
                    this.stateLTE = 1;
                    this.state5GSA = 0;
                    this.state5GNSA = 0;
                    this.stateNO = 0;
                    this.stateWcdma = 0;
                    this.countCellB1++;
                  } else {
                    this.cellLTE2 = scanResult;
                    this.state5GNSA2 = 0;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 1;
                    this.stateNO2 = 0;
                    this.stateWcdma2 = 0;
                    this.countCellB2++;
                  }
                  this.checkCell(scanResult.cellcode, 'lte', scanResult.frequencyBand, scanResult.rsrp);
                  if (scanResult.cellcodeS != undefined) {
                    this.checkCell(scanResult.cellcodeS, 'lte', scanResult.frequencyBand, scanResult.rsrp);
                  }
                } else if (scanResult instanceof ScanResultS_noService) {
                  this.scanResultsS = scanResult.ScanNumber + 1;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cellNoSer = scanResult;
                    this.stateNO = 1;
                    this.state5GSA = 0;
                    this.state5GNSA = 0;
                    this.stateWcdma = 0;
                    this.stateLTE = 0;
                    this.countCellB1++;
                  } else {
                    this.cellNoSer2 = scanResult; this.state5GNSA2 = 0;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 0;
                    this.stateNO2 = 1;
                    this.countCellB2++;
                  }
                } else if (scanResult instanceof ScanResultS_wcdma) {
                  this.scanResultsS = scanResult.ScanNumber + 1;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cellwcdma = scanResult;
                    this.stateNO = 0;
                    this.state5GSA = 0;
                    this.state5GNSA = 0;
                    this.stateWcdma = 1;
                    this.stateLTE = 0;
                    this.countCellB1++;
                  } else {
                    this.cellwcdma2 = scanResult;
                    this.state5GNSA2 = 0;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 1;
                    this.stateNO2 = 0;
                    this.countCellB2++;
                  }
                  this.checkCell(scanResult.cellcode, 'wcdma', scanResult.frequencyBand, -1);
                  if (scanResult.cellcodeS != undefined) {
                    this.checkCell(scanResult.cellcodeS, 'wcdma', scanResult.frequencyBand, -1);
                  }
                } else if (scanResult instanceof ScanResultH) {
                  this.scanResultsH = scanResult.ScanNumber;
                  if (this.isScanning == true) {
                    this.listCoordScansioni.push(new ScanMap(1, "", (Number)($BTSTracker.value.longitude.toFixed(5)), (Number)($BTSTracker.value.latitude.toFixed(5))));
                  }
                } else if (scanResult instanceof ScanGPS) {
                  this.latitude = scanResult.latitude;
                  this.longitude = scanResult.longitude;
                  this.altitude = Number(scanResult.altitude);
                }
              }
              this.scanResults = this.scanResultsH + this.scanResultsS + this.scanResultWifi + this.scanResultBlue + this.scanResultActive;
              let conta = 0;
              for (let i = 0; i < this.interestedCell.length; i++) {
                conta = conta + this.interestedCell[i].number;
              }
              this.totalInterestedCell = conta;
            }
          }
          else {
            const record = CellRecord.fromCsv(str);
            if (record) {
              record.fileName = $BTSTracker.value.fileName;
              $BTSTracker.value.scanResultsS++;
              $BTSTracker.value.scanResultsH++;
              const event = new CustomEvent('new-cell', { detail: record });
              window.dispatchEvent(event);
            }
          }

        } catch (e) {
          //console.log("scan Feedback Chara B");
          const rsp = new Int32Array([1]).buffer;
          await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, COMMAND_SERVICE_ID, SCAN_FEEDBACK_CHARA, new DataView(rsp, 0, rsp.byteLength));
        }
        $BTSTracker.value.availableAt = new Date().getTime();
      }
    } catch (e) {
      //console.log(e);
    }
  }

  checkCell(CellCode: string, Technology: string, FreqBand: string, Power: number) {
    CellCode = CellCode.replaceAll(" | ", "");
    for (let i = 0; i < this.interestedCell.length; i++) {
      if (CellCode == this.interestedCell[i].name) { // || this.interestedInfo(CellCode)
        this.interestedCell[i].number++;
        this.interestedCell[i].type = "connected " + Technology.toUpperCase();
      }
    }
  }

  handleCommandFeedback(result: DataView) {
    if ($BTSTracker.value.connectedDeviceId) {
      const str = BTSTracker.stringDecoder.decode(result);
      //console.log(str);
      if ($BTSTracker.value.currentCommandObject) {
        try {
          const obj = JSON.parse(str);
          $BTSTracker.value.currentCommandObject.commandResolverOk(obj); // resolve the promise
          if (obj.ok) {
            if (obj.msg === "scan_started") {
              $BTSTracker.value.isScanning = true;
            }
            if (obj.msg === "scan_stopped") {
              $BTSTracker.value.isScanning = false;
            }
          }
        } catch (e) {
          //console.error(e);
          //console.warn("This is the json with troubles " + str);
        }
        $BTSTracker.value.currentCommandObject = undefined;

      } else {
        //console.log("Receiving command feedback but no resolver");
      }
    } else {
      //console.log("Receiving command feedback but not waiting for it");
    }
  }

  async updateInfos() {

    // GET INFO CELL
    if (this.startScan) {
      const cell: Record<string, any> = await this.sendCommand("get_cell", {});
      //console.log(JSON.stringify(cell));
      if (cell.ok) {
        this.cellList = String(cell.msg.cell);
      }
      /*const info: Record<string, any> = await this.sendCommand("get_info_search", {});
      if(info.ok){
        const allInfo = String(cell.msg.info).split("-");
        this.mcc = allInfo[0];
        this.mnc = allInfo[1];
        this.lac = allInfo[2];
        this.cellid = allInfo[3];
      }*/
    }

    if (!this.connected || !this.connectedDeviceId) return;
    if (this.appLocation)
      this.sendLocationToBtsTracker(this.appLocation);

    // SET TIME
    await this.sendCommand("set_time_device", { "time": this.deviceDataTime });


    // RESTART
    const restart: Record<string, any> = await this.sendCommand("restart", {});
    //console.log(restart);
    if (restart && restart.ok == true) {
      this.restart = true;
      this.fileName = String(restart.msg.name);
      const technology = String(restart.msg.type);
      this.technologies = technology.split(',');
      this.modeS = String(restart.msg.mode);
      if (this.technologies.indexOf("AutoConnection5G") >= 0) {
        this.connectedCell = true;
        if (this.modeS == '2' || this.modeS == '54' || this.modeS == '55') {
          this.fallback = true;
        } else {
          this.fallback = false;
        }
        if (this.modeS == '14') {
          this.autoConnection = 'UMTS';
        }
        if (this.modeS == '38' || this.modeS == '54') {
          this.autoConnection = 'LTE';
          this.fallback = true;
        }
        if (this.modeS == '71' || this.modeS == '55') {
          this.autoConnection = '5G';
        }
      } else {
        this.connectedCell = false;
      }
      //console.log(this.connectedCell)
      if (this.technologies.indexOf("LTE") >= 0 || this.technologies.indexOf("UMTS") >= 0 || this.technologies.indexOf("GSM") >= 0) {
        this.availableCell = true;
      } else {
        this.availableCell = false;
      }
      if (this.technologies.indexOf("wifi") >= 0) {
        this.wifi = true;
      } else {
        this.wifi = false;
      }
      if (this.technologies.indexOf("blue") >= 0) {
        this.blue = true;
      } else {
        this.blue = false;
      }
      if (this.technologies.indexOf("active") >= 0) {
        this.activeSet = true;
      } else {
        this.activeSet = false;
      }
      this.isScanning = true;
    }

    const scanning = await this.sendCommand("is_scanning", {}); // ask for the status of the scanner
    const msg = "" + scanning.msg as string;
    //console.warn(scanning)
    if (scanning.ok && msg.length > 1) {
      const splitted = msg.split(";");
      if (splitted[0] == "scanning" && this.restart == false) {
        this.NameFile = splitted[2];
        if (this.NameFile.endsWith(".scscan")) {
          this.NameFile = this.NameFile.substring(0, this.NameFile.length - 7);
        }
        if (splitted.length > 3) {
          this.timeStartScan = splitted[3];
        }
        this.technologies = [];
        splitted[1].split(",").forEach(tech => {
          this.technologies.push(tech);
        });
        //console.log(this.technologies);
        if (this.technologies.indexOf("GSM") >= 0 || this.technologies.indexOf("LTE") >= 0 || this.technologies.indexOf("UMTS") >= 0) {
          this.availableCell = true;
        } else {
          this.availableCell = false;
        }
        if (this.technologies.indexOf("AutoConnection5G") >= 0) {
          this.connectedCell = true;
        } else {
          this.connectedCell = false;
        }
        if (this.technologies.indexOf("wifi") >= 0) {
          this.wifi = true;
        } else {
          this.wifi = false;
        }
        if (this.technologies.indexOf("blue") >= 0) {
          this.blue = true;
        } else {
          this.blue = false;
        }
        if (this.technologies.indexOf("active") >= 0) {
          this.activeSet = true;
        } else {
          this.activeSet = false;
        }
        this.isScanning = true;
      }
      if (this.init == 0) {
        this.readyForScanning = false;
      } else {
        this.readyForScanning = true;
      }
    } else {
      this.isScanning = false;
    }

    // BATTERIA
    this.getBattery();

    // INFO
    const getinfoBtsJson: Record<string, any> = await this.sendCommand("get_btsinfo_json", {});
    //console.log(getinfoBtsJson);
    if (getinfoBtsJson && getinfoBtsJson.ok) {
      if (getinfoBtsJson.msg.deviceSerial) {
        $BTSTracker.value.serial = String(getinfoBtsJson.msg.deviceSerial);
        $BTSTracker.value.version = String(getinfoBtsJson.msg.fwVersion);
      }
      // DATE
      $BTSTracker.value.datetime = String(getinfoBtsJson.msg.date);
      const dt = $BTSTracker.value.datetime.split("T");
      $BTSTracker.value.deviceData = dt[0];
      $BTSTracker.value.deviceTime = dt[1];
      const d = $BTSTracker.value.deviceData.split("-");
      const h = $BTSTracker.value.deviceTime.split(":");

      $BTSTracker.value.modA = Number(getinfoBtsJson.msg.mod1);
      $BTSTracker.value.modB = Number(getinfoBtsJson.msg.mod2);

      $BTSTracker.value.numSim = (Number)(getinfoBtsJson.msg.count);
      $BTSTracker.value.simH = (getinfoBtsJson.msg.a1);
      $BTSTracker.value.simS1 = (getinfoBtsJson.msg.b1);
      $BTSTracker.value.simS2 = (getinfoBtsJson.msg.b2);
      this.setOperatori();
      $BTSTracker.value.pin1 = (getinfoBtsJson.msg.p1);
      $BTSTracker.value.pin2 = (getinfoBtsJson.msg.p2);
      if ($BTSTracker.value.pin1.indexOf("/") >= 0) {
        $BTSTracker.value.pin1 = "";
      }
      if ($BTSTracker.value.pin2.indexOf("/") >= 0) {
        $BTSTracker.value.pin2 = "";
      }
      if ($BTSTracker.value.simS1.length > 0) {
        $BTSTracker.value.slot1 = true;
      } else {
        $BTSTracker.value.slot1 = false;
      }
      if ($BTSTracker.value.simS2.length > 0) {
        $BTSTracker.value.slot2 = true;
      } else {
        $BTSTracker.value.slot2 = false;
      }

      if (this.perc == 0) {
        this.perc = 20;
      }

      if ($BTSTracker.value.simS1.length == 0) {
        //console.log("non c'è 1");
        this.imsi1_old = "";
        this.apn1 = "";
        this.userApn1 = "";
        this.passApn1 = "";
      }
      if ($BTSTracker.value.simS2.length == 0) {
        //console.log("non c'è 2");
        this.imsi2_old = "";
        this.apn2 = "";
        this.userApn2 = "";
        this.passApn2 = "";
      }
      //console.log(this.apn2);
      if (this.simS1.length == 0 && this.simS2.length == 0) {
        this.connectedCell = false;
      }
      if (this.check == false) {
        if (this.simH.length > 0 && !this.isScanning) {
          this.activeSet = true; this.availableCell = false;
          this.check = true;
        } else if (!this.isScanning) {
          this.activeSet = false; this.availableCell = true;
        }
      }

      $BTSTracker.value.moduleWifi = Number(getinfoBtsJson.msg.wifi_module);
      if ($BTSTracker.value.moduleWifi == 1 && $BTSTracker.value.isScanning == false) {
        $BTSTracker.value.wifi = true;
        $BTSTracker.value.blue = true;
      } else if ($BTSTracker.value.moduleWifi == 0 && $BTSTracker.value.isScanning == false) {
        $BTSTracker.value.wifi = true; $BTSTracker.value.blue = false;
      }
    }

    this.updateSettings();

    const getinfoJson: Record<string, any> = await this.sendCommand("get_info_json", {});
    //console.log(getinfoJson)
    //SD
    if (this.updateSD == false) {
      $BTSTracker.value.sdCardError = Boolean(getinfoJson.msg.error);
      if (!$BTSTracker.value.sdCardError) {
        $BTSTracker.value.sdCardTotalSize = Number(getinfoJson.msg.full);
        $BTSTracker.value.sdCardUsedSize = Number(getinfoJson.msg.used);
      } else {
        $BTSTracker.value.sdCardTotalSize = 0;
        $BTSTracker.value.sdCardUsedSize = 0;
      }

      this.caricamentoSD = false;
    }

    if (JSON.stringify(getinfoJson).indexOf("v") >= 0) {
      const modules = getinfoJson.msg.v.split(";");
      if (this.modA == 1) {
        this.moduleA = modules[0];
      }
      if (this.modB == 1) {
        this.moduleB = modules[1];
      }
    }
    if (getinfoJson && getinfoJson.ok == true) {
      $BTSTracker.value.init = Number(getinfoJson.msg.init);

      if ($BTSTracker.value.init == 1 && $BTSTracker.value.moduleWifi != -1) {
        $BTSTracker.value.perc = 100;
        $BTSTracker.value.readyForScanning = true;
      } else if (this.init != 1) {
        $BTSTracker.value.perc = Number(getinfoJson.msg.perc);
      }

    }
    if ($BTSTracker.value.init == 1 && $BTSTracker.value.moduleWifi != -1) {
      $BTSTracker.value.readyForScanning = true;
    }
  }

  async getBattery() {
    const batteryPercentagesjson: Record<string, any> = await this.sendCommand("get_battery_json", {});
    //console.log(batteryPercentagesjson.msg);
    if (batteryPercentagesjson && batteryPercentagesjson.ok == true) {
      $BTSTracker.value.millivolts = Number(batteryPercentagesjson.msg.Millivolts);
      $BTSTracker.value.currVoltage = Number(batteryPercentagesjson.msg.currVoltage);
      $BTSTracker.value.usbConnected = Number(batteryPercentagesjson.msg.usbConnected);
      $BTSTracker.value.acok = Number(batteryPercentagesjson.msg.acok);
      $BTSTracker.value.batteryPercentage = Number(batteryPercentagesjson.msg.level);
      if ($BTSTracker.value.batteryPercentage < 0)
        $BTSTracker.value.batteryPercentage = 0;
      if ($BTSTracker.value.batteryPercentage > 100)
        $BTSTracker.value.batteryPercentage = 100;
      $BTSTracker.value.mediaBatteria = batteryPercentagesjson.msg.media;
      if ($BTSTracker.value.mediaBatteria < 0)
        $BTSTracker.value.mediaBatteria = 0;
      if ($BTSTracker.value.mediaBatteria > 100)
        $BTSTracker.value.mediaBatteria = 100;
    }
  }

  async updateSDCard() {
    const getsd: Record<string, any> = await this.sendCommand("get_sdCard", {});
    if (getsd && getsd.ok) {
      //SD
      $BTSTracker.value.sdCardError = Boolean(getsd.msg.error);
      $BTSTracker.value.sdCardTotalSize = Number(getsd.msg.full);
      $BTSTracker.value.sdCardUsedSize = Number(getsd.msg.used);
      this.caricamentoSD = false;
      this.updateSD = false;
    }
  }

  async apn_ftp() {
    //console.warn("apn");
    const getApn: Record<string, any> = await this.sendCommand("get_apn", {});
    //console.warn(getApn);
    if (getApn.ok && this.isScanning == false) {
      //console.log("here");
      this.apnSettings = true;
      this.imsi1_old = String(getApn.msg.imsi1);

      this.apn1 = String(getApn.msg.APN1);
      this.userApn1 = String(getApn.msg.uAPN1);
      this.passApn1 = String(getApn.msg.pAPN1);
      this.imsi2_old = String(getApn.msg.imsi2);

      this.apn2 = String(getApn.msg.APN2);
      this.userApn2 = String(getApn.msg.uAPN2);
      this.passApn2 = String(getApn.msg.pAPN2);

      //console.log(this.apn1);

      if (this.simS1.length > 0 && this.simS2.length > 0) {
        if (this.apn1.length == 0) {
          this.sceltaSim = "2";
        } else {
          this.sceltaSim = "1";
        }
      } else {
        if (this.simS1.length > 0 && this.apn1.length > 0) {
          this.sceltaSim = "1";
        }
        if (this.simS2.length > 0 && this.apn2.length > 0) {
          this.sceltaSim = "2";
        }
      }

    } else {
      this.apnSettings = true;
    }
    if (getApn.ok) {
      if (this.init == 1 && this.checkimsi == true && this.atLeastAsim()) {
        if (this.imsi1_old != this.simS1) {
          this.differentSim1 = true;
          createToast({
            title: "Warning, the SIM 1 is different from last time",
            description: "change APN settings",
          }, {
            type: 'warning',
            showIcon: true,
            hideProgressBar: true,
          });
        }
        if (this.imsi2_old != this.simS2) {
          this.differentSim2 = true;
          createToast({
            title: "Warning, the SIM 2 is different from last time",
            description: "change APN settings",
          }, {
            type: 'warning',
            showIcon: true,
            hideProgressBar: true,
          });
        }
        this.checkimsi = false;
      }
    }
    if (this.apn1.length > 0 && this.APNlist.indexOf("B1") < 0) {
      this.APNlist += "B1 "
    }
    if (this.apn2.length > 0 && this.APNlist.indexOf("B2") < 0) {
      this.APNlist += "B2";
    }
    if (this.apn1.length == 0 && this.apn2.length == 0) {
      this.APNlist = "";
    }

    //console.log(this.APNlist)

    const getFtp: Record<string, any> = await this.sendCommand("get_ftp", {});
    if (this.ftpSettings == false) {
      if (getFtp.ok && JSON.stringify(getApn.msg).length > 2 && this.staticInfo == false) {
        this.ftpSettings = true;
        this.ftp = String(getFtp.msg.FTP);
        this.userFtp = String(getFtp.msg.uFTP);
        this.passFtp = String(getFtp.msg.pFTP);
        if (this.ftp.length == 0) {
          this.enableapn = false;
        }
      } else {
        this.ftpSettings = true;
      }
    }
  }

  async updateSettings() {
    //console.log(this.isScanning);
    if (this.isScanning == false) {
      this.apn_ftp();
      const otaError = await this.sendCommand("get_error", {});
      if (otaError.ok) {
        const s = (otaError.msg as string).split(";");
        $BTSTracker.value.errorOta = s[0];
      }
    }
  }

  setOperatori() {
    const mcc_mnc1 = this.simS1.slice(0, 3) + "-" + this.simS1.slice(3, 5);
    this.opeSim1 = ScanResult.getOperatore(mcc_mnc1);
    const mcc_mnc2 = this.simS2.slice(0, 3) + "-" + this.simS2.slice(3, 5);
    this.opeSim2 = ScanResult.getOperatore(mcc_mnc2);
    const mcc_mnc0 = this.simH.slice(0, 3) + "-" + this.simH.slice(3, 5);
    this.opeSim0 = ScanResult.getOperatore(mcc_mnc0);
  }

  async getResponceAPN() {
    const getInfo: Record<string, any> = await this.sendCommand("get_responceApn", {});
    if (this.apn1.length > 0 && this.APNlist.indexOf("B1") < 0) {
      this.APNlist += "B1 "
    }
    if (this.apn2.length > 0 && this.APNlist.indexOf("B2") < 0) {
      this.APNlist += "B2";
    }
    if (this.apn1.length == 0 && this.apn2.length == 0) {
      this.APNlist = "";
    }
    //console.log(this.APNlist)
    if (getInfo.ok) {
      createToast({
        title: "APN settings saved"
      }, {
        type: 'success',
        showIcon: true,
        hideProgressBar: true,
      });
    } else {
      createToast({
        title: "Error saving APN settings",
      }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      });
    }
    this.apnSettings = true;
  }

  async getResponceFTP() {
    const getInfo: Record<string, any> = await this.sendCommand("get_responceFtp", {});
    if (getInfo.ok) {
      createToast({
        title: "FTP settings saved",
      }, {
        type: 'success',
        showIcon: true,
        hideProgressBar: true,
      });
    } else {
      createToast({
        title: "Error saving FTP settings",
      }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      });
    }
    this.ftpSettings = true;
  }

  public atLeastAsim() {
    if (this.simS1.length > 0 || this.simS2.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public atLeastAapn() {
    if (this.apn1.length > 0 || this.apn2.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public async sendCommand(cmd: string, data: Record<string, unknown>): Promise<Record<string, unknown>> {
    if (!this.connected || !this.connectedDeviceId) throw "Not connected";
    const object = {
      "cmd": cmd,
    } as Record<string, unknown>;
    if (Object.keys(data).length > 0) object["data"] = data; // put the data only if it's not empty

    let rsv: (a: Record<string, unknown>) => void = () => { return; }; // create the resolver
    const prm = new Promise<Record<string, unknown>>((resolve) => rsv = resolve); // create the promise
    const commandObj = new Command(object, rsv); // create the command object
    this.commands.push(commandObj); // add the command to the commands queue
    if (!this.connected || !this.connectedDeviceId) throw "Not connected";
    return prm; // return the promise
  }


  public async disconnect() {
    if ($BTSTracker.value.connected && $BTSTracker.value.connectedDeviceId) {
      try {
        await BleClient.disconnect($BTSTracker.value.connectedDeviceId.deviceId);
      } catch (e) {
        // ignored
      }
      $BTSTracker.value.connectedDeviceId = undefined;
      $BTSTracker.value.connected = false;
      $BTSTracker.value.connecting = false;
      this.caricamentoSD = true;
    }
  }

  public isConnected(): boolean {
    return $BTSTracker.value.connected;
  }

  public getbatteryPercentage(): number {
    return $BTSTracker.value.mediaBatteria;
  }


  public async downloadFile(fileName: string): Promise<FileBuffer> {
    if (!$BTSTracker.value.connected || !$BTSTracker.value.connectedDeviceId) {
      createToast(
        {
          title: "Not connected"
        }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      }
      ); throw "Not connected";
    }
    if ($BTSTracker.value.fileBuffer || $BTSTracker.value.fileBufferPromiseResolver) {
      createToast(
        {
          title: "A file is already begin downloaded"
        }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      }
      );
      throw "A file is already begin downloaded";
    }

    const cmdRsp = await this.sendCommand("download_file", { "file_name": fileName, "folder": this.folder }); // this return the file data, how many parts if exist and so on
    //console.log(cmdRsp);
    //console.log(fileName);
    if (cmdRsp.ok) {
      const msg = "" + cmdRsp.msg as string;
      const parts = parseInt(msg);
      //console.log("File parts " + parts);
      const fBuffer = new FileBuffer(fileName, parts);
      $BTSTracker.value.fileBuffer = fBuffer;
      let rsv: ((a: FileBuffer) => void) = () => { return; };
      const prm = new Promise<FileBuffer>((resolve) => rsv = resolve);
      $BTSTracker.value.fileBufferPromiseResolver = rsv;
      await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_COMMAND_FB_CHARA, new DataView(new Int32Array([0]).buffer, 0, 4));
      //console.log("Download file method finished his execution");
      if (parts == 0) {
        if (rsv) {
          rsv($BTSTracker.value.fileBuffer);
          $BTSTracker.value.fileBuffer = undefined;
          $BTSTracker.value.fileBufferPromiseResolver = undefined;
          //console.log("!! FILE DOWNLOADED !!");
        }
      }
      return prm;
    } else {
      throw "Error while downloading a file: " + cmdRsp.msg as string;
    }
  }

  public async sendFileDownloadRequest(): Promise<FileBuffer> {
    if (!$BTSTracker.value.connected || !$BTSTracker.value.connectedDeviceId) {
      createToast(
        {
          title: "Not connected"
        }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      }
      ); throw "Not connected";
    }

    const cmdRsp = await this.sendCommand("test_file", {}); // this return the file data, how many parts if exist and so on
    if (cmdRsp.ok) {
      const msg = "" + cmdRsp.msg as string;
      const parts = parseInt(msg);
      const fBuffer = new FileBuffer("/BTSdetails.txt", parts);
      $BTSTracker.value.fileBuffer = fBuffer;
      let rsv: ((a: FileBuffer) => void) = () => { return; };
      const prm = new Promise<FileBuffer>((resolve) => rsv = resolve);
      $BTSTracker.value.fileBufferPromiseResolver = rsv;
      await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_COMMAND_FB_CHARA, new DataView(new Int32Array([0]).buffer, 0, 4));
      if (parts == 0) {
        if (rsv) {
          rsv($BTSTracker.value.fileBuffer);
          $BTSTracker.value.fileBuffer = undefined;
          $BTSTracker.value.fileBufferPromiseResolver = undefined;
          //console.log("!! FILE DOWNLOADED !!");
        }
      }
      return prm;
    } else {
      createToast(
        {
          title: "Error"
        }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      }
      );
      throw "Error while downloading a file: " + cmdRsp.msg as string;
    }
  }


  public async getFolders(): Promise<FoldersBuffer> {
    //console.log("get files");
    if (!$BTSTracker.value.connected || !$BTSTracker.value.connectedDeviceId) throw "Not connected";
    if ($BTSTracker.value.fileBuffer || $BTSTracker.value.fileBufferPromiseResolver) throw "A file is already begin downloaded";
    if ($BTSTracker.value.folderBuffer || $BTSTracker.value.folderBufferPromiseResolver) throw "A folder is already begin downloaded";

    const cmdResp = await this.sendCommand("get_files", { "path": $BTSTracker.value.folder }); // this return the file data, how many parts if exist and so on
    if (!cmdResp.ok) throw "Error while Fetching files: " + cmdResp.msg as string;
    //console.log(cmdResp);
    let msg = "";
    if (cmdResp.msg == "deleteAllFiles") {
      msg = "0";
    } else {
      msg = "" + cmdResp.msg as string;
    }
    const parts = parseInt(msg);
    //console.log("Folder parts " + parts);
    const fBuffer = new FoldersBuffer(parts);
    $BTSTracker.value.folderBuffer = fBuffer;
    let rsv;
    const prm = new Promise<FoldersBuffer>((resolve) => rsv = resolve);
    $BTSTracker.value.folderBufferPromiseResolver = rsv;
    await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_COMMAND_FB_CHARA, new DataView(new Int32Array([0]).buffer, 0, 4));
    $BTSTracker.value.availableAt = new Date().getTime();
    return prm;
  }

  async handleChecksumFeedback(result: DataView) {
    //console.log("handleChecksumFeedback");
    if (!$BTSTracker.value.connected || !$BTSTracker.value.connectedDeviceId) throw "Not connected";
    if (!$BTSTracker.value.fileBuffer || !$BTSTracker.value.fileBufferPromiseResolver) throw "No file is being downloaded";
    $BTSTracker.value.fileBuffer.currentChecksum = BTSTracker.stringDecoder.decode(result);
    //console.log("RECEIVED CHECKSUM --> " + $BTSTracker.value.fileBuffer.currentChecksum);
    await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_COMMAND_FB_CHARA, new DataView(new Int32Array([0]).buffer, 0, 4));
  }

  async handleFileManagerReceiver(result: DataView) {
    //console.log("handleFileManagerReceiver");
    if (!$BTSTracker.value.connected || !$BTSTracker.value.connectedDeviceId) throw "Not connected";
    if ($BTSTracker.value.fileBuffer && $BTSTracker.value.fileBufferPromiseResolver) {
      $BTSTracker.value.fileBuffer.addPart(result);
      $BTSTracker.value.fileBuffer.currentChecksum = "";
      if ($BTSTracker.value.fileBuffer.isComplete()) {
        if ($BTSTracker.value.fileBufferPromiseResolver) {
          $BTSTracker.value.fileBufferPromiseResolver($BTSTracker.value.fileBuffer);
          $BTSTracker.value.fileBuffer = undefined;
          $BTSTracker.value.fileBufferPromiseResolver = undefined;
          //console.log("!! FILE DOWNLOADED !!");
        }
      }
    }

    if ($BTSTracker.value.folderBuffer) {
      const data = BTSTracker.stringDecoder.decode(result);
      $BTSTracker.value.folderBuffer.addPart(data);

      await BleClient.write($BTSTracker.value.connectedDeviceId.deviceId, FILEMANAGER_SERVICE_CHARA, FILEMANAGER_COMMAND_FB_CHARA, new DataView(new Int32Array([0]).buffer, 0, 4));

      if ($BTSTracker.value.folderBuffer != undefined) {
        if ($BTSTracker.value.folderBuffer.isComplete()) {
          if ($BTSTracker.value.folderBufferPromiseResolver) {
            $BTSTracker.value.folderBufferPromiseResolver($BTSTracker.value.folderBuffer);
            $BTSTracker.value.folderBuffer = undefined;
            $BTSTracker.value.folderBufferPromiseResolver = undefined;
            //console.log("!! FOLDER DOWNLOADED !!");
          }
        }
      }
    }
    $BTSTracker.value.availableAt = new Date().getTime();
  }
}

const _downloadFile = (data: string, fileName: string, type: string) => {

  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = data;

  // Use download attribute to set set desired file name
  a.setAttribute("download", fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export const $BTSTracker = ref(new BTSTracker());
