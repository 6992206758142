/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      const item = document.getElementById("info-pre-app");
      if (item) item.innerText = "Initializing...";
    },
    registered() {
      //console.log('Service worker has been registered.')
      const item = document.getElementById("info-pre-app");
      if (item) item.innerText = "Initializing...";
    },
    cached() {
      //console.log('Content has been cached for offline use.')
      window.sessionStorage.removeItem("updating");
      window.setTimeout(() => window.location.reload(), 2500);
    },
    updatefound() {
      //console.log('New content is downloading.')
      window.sessionStorage.setItem("updating", "true");
      const item = document.getElementById("info-pre-app");
      if (item) {
        item.innerText = "Updating the app...";
      }
    },
    updated() {
      //console.log('New content is available; please refresh.')
      window.sessionStorage.removeItem("updating");
      const item = document.getElementById("info-pre-app");
      if (item) {
        item.innerText = "App updated.";
      }
      window.setTimeout(() => window.location.reload(), 2500);
    },
    offline() {
      //console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      //console.error('Error during service worker registration:', error)
    }
  })
}