import { ITable, DATA_TYPE, IDataBase, Connection, ISelectQuery } from "jsstore";
import workerInjector from "jsstore/dist/worker_injector";
import { CellRecord, SortingPreferences, Carrier, CarrierCountry } from "./model";
import { RawSortingPreferences } from "./models/SortingPref";

export const connection = new Connection();
const dbname = "data";
connection.addPlugin(workerInjector);
if (process.env.NODE_ENV !== 'production') {
  connection.logStatus = true;
}


const getDatabase = () => {
  
  // colonne da visualizzare nelle scansioni
  const tableColumnsCell : ITable = {
    name: "TableColumnsCell",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true
      },
      cell : {
        dataType: DATA_TYPE.Boolean,
        default: true
      }
    }
  };

  /*
  const tblSortingPreferences: ITable = {
    name: 'sorting_preferences',
    columns: {
      name: {
        primaryKey: true,
        dataType: DATA_TYPE.String,
        unique: true
      },
      sortById: {
        dataType: DATA_TYPE.Number
      },
      ascending: {
        dataType: DATA_TYPE.Boolean
      },
      carriers: {
        dataType: DATA_TYPE.String,
      },
      technologies: {
        dataType: DATA_TYPE.String,
      }
    }
  };

  const tblCarrierCountries: ITable = {
    name: 'carrier_countries',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true
      },
      country: {
        dataType: DATA_TYPE.String,
      },
    }
  };

  */
  const dataBase: IDataBase = {
    name: dbname,
    tables: [tableColumnsCell]
  };
  return dataBase;
};

export const initDatabase = () => {
  try {
    const dataBase = getDatabase();
    connection.initDb(dataBase);
  } catch (ex) {
    console.error(ex);
  }
};

class Database {
  
}
export const $database = new Database();