import { Point } from "./models/Point";
import { CellRecord } from "./models/CellRecord";
import { BTSTracker, $BTSTracker } from "./models/BTSTracker";
import { SortingPreferences } from "./models/SortingPref";
import { FolderContainer } from "./models/FolderContainer";
import { Log } from "./models/Log";
import { Carrier, CarrierCountry } from "./models/Carrier";
import { $database } from "./jsstore_con";

export const VERSION = "1.1.0.24142";

export class CellsTableSortMessage {
  data: CellRecord[];
  preferences: SortingPreferences;
  carriers: Carrier[];
  constructor(data: CellRecord[], preferences: SortingPreferences, carriers: Carrier[]) {
    this.data = data;
    this.preferences = preferences;
    this.carriers = carriers;
  }
}

export { Point, CellRecord, BTSTracker, $BTSTracker, SortingPreferences, FolderContainer, Log, Carrier, $database, CarrierCountry };