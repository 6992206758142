import { $BTSTracker, BTSTracker } from "./BTSTracker";
const gsmBands = [
  { band: 'GSM 450', earfcn: [259, 293] }, { band: 'GSM 480', earfcn: [306, 340] }, { band: 'GSM 750', earfcn: [438, 511] }, { band: 'GSM 850', earfcn: [128, 251] },
  { band: 'P-GSM', earfcn: [1, 124] }, { band: 'E-GSM', earfcn: [975, 1023] }, { band: 'GSM-R', earfcn: [955, 1023] }, { band: 'DCS 1800', earfcn: [512, 885] },
  { band: 'PCS 1900', earfcn: [512, 810] }
];
const umtsBands = [
  { band: 'B1', earfcn: [10562, 10838] }, { band: 'B2', earfcn: [9662, 9938] }, { band: 'B3', earfcn: [1162, 1513] },
  { band: 'B4', earfcn: [1537, 1738] }, { band: 'B5', earfcn: [4357, 4458] }, { band: 'B6', earfcn: [4387, 4413] }, { band: 'B7', earfcn: [2237, 2563] },
  { band: 'B8', earfcn: [2937, 3088] },
  { band: 'B9', earfcn: [9237, 9387] },
  { band: 'B10', earfcn: [3112, 3388] },
  { band: 'B11', earfcn: [3712, 3787] },
  { band: 'B12', earfcn: [3842, 3903] },
  { band: 'B13', earfcn: [4017, 4043] },
  { band: 'B14', earfcn: [4117, 4143] },
  { band: 'B19', earfcn: [712, 763] },
  { band: 'B20', earfcn: [4512, 4638] },
  { band: 'B21', earfcn: [862, 912] },
  { band: 'B22', earfcn: [4662, 5038] },
  { band: 'B25', earfcn: [5112, 5413] },
  { band: 'B26', earfcn: [5762, 5913] },
  { band: 'B32', earfcn: [6617, 6813] }
];
const lteBands = [
  { band: '1', up_fr: [1920, 1980], up_earfcn: [18000, 18599], dl_fr: [2110, 2170], dl_earfcn: [0, 599], duplex: 'FDD' },
  { band: '2', up_fr: [1850, 1910], up_earfcn: [18600, 19199], dl_fr: [1930, 1990], dl_earfcn: [600, 1199], duplex: 'FDD' },
  { band: '3', up_fr: [1710, 1785], up_earfcn: [19200, 19949], dl_fr: [1805, 1880], dl_earfcn: [1200, 1949], duplex: 'FDD' },
  { band: '4', up_fr: [1710, 1755], up_earfcn: [19950, 20399], dl_fr: [2110, 2155], dl_earfcn: [1950, 2399], duplex: 'FDD' },
  { band: '5', up_fr: [824, 849], up_earfcn: [20400, 20649], dl_fr: [869, 894], dl_earfcn: [2400, 2649], duplex: 'FDD' },
  { band: '7', up_fr: [2500, 2570], up_earfcn: [20750, 21449], dl_fr: [2620, 2690], dl_earfcn: [2750, 3449], duplex: 'FDD' },
  { band: '8', up_fr: [880, 915], up_earfcn: [21450, 21799], dl_fr: [925, 960], dl_earfcn: [3450, 3799], duplex: 'FDD' },
  { band: '9', up_fr: [1749.9, 1784.9], up_earfcn: [21800, 22149], dl_fr: [1844.9, 1879.9], dl_earfcn: [3800, 4149], duplex: 'FDD' },
  { band: '10', up_fr: [1710, 1770], up_earfcn: [22150, 22749], dl_fr: [2110, 2170], dl_earfcn: [4150, 4749], duplex: 'FDD' },
  { band: '11', up_fr: [1427.9, 1447.9], up_earfcn: [22750, 22949], dl_fr: [1475.9, 1495.9], dl_earfcn: [4750, 4949], duplex: 'FDD' },
  { band: '12', up_fr: [699, 716], up_earfcn: [23010, 23179], dl_fr: [729, 746], dl_earfcn: [5010, 5179], duplex: 'FDD' },
  { band: '13', up_fr: [777, 787], up_earfcn: [23180, 23279], dl_fr: [746, 756], dl_earfcn: [5180, 5279], duplex: 'FDD' },
  { band: '14', up_fr: [788, 798], up_earfcn: [23280, 23379], dl_fr: [758, 768], dl_earfcn: [5280, 5379], duplex: 'FDD' },
  { band: '17', up_fr: [704, 716], up_earfcn: [23730, 23849], dl_fr: [734, 746], dl_earfcn: [5730, 5849], duplex: 'FDD' },
  { band: '18', up_fr: [815, 830], up_earfcn: [23850, 23999], dl_fr: [860, 875], dl_earfcn: [5850, 5999], duplex: 'FDD' },
  { band: '19', up_fr: [830, 845], up_earfcn: [24000, 24149], dl_fr: [875, 890], dl_earfcn: [6000, 6149], duplex: 'FDD' },
  { band: '20', up_fr: [832, 862], up_earfcn: [24150, 24449], dl_fr: [791, 821], dl_earfcn: [6150, 6449], duplex: 'FDD' },
  { band: '21', up_fr: [1447.9, 1462.9], up_earfcn: [24450, 24599], dl_fr: [1495.9, 1510.9], dl_earfcn: [6450, 6599], duplex: 'FDD' },
  { band: '22', up_fr: [3410, 3490], up_earfcn: [24600, 25399], dl_fr: [3510, 3590], dl_earfcn: [6600, 7399], duplex: 'FDD' },
  { band: '24', up_fr: [1626.5, 1660.5], up_earfcn: [25700, 26039], dl_fr: [1525, 1559], dl_earfcn: [7700, 8039], duplex: 'FDD' },
  { band: '25', up_fr: [1850, 1915], up_earfcn: [26040, 26689], dl_fr: [1930, 1995], dl_earfcn: [8040, 8689], duplex: 'FDD' },
  { band: '26', up_fr: [814, 849], up_earfcn: [26690, 27039], dl_fr: [859, 894], dl_earfcn: [8690, 9039], duplex: 'FDD' },
  { band: '27', up_fr: [807, 824], up_earfcn: [27040, 27209], dl_fr: [852, 869], dl_earfcn: [9040, 9209], duplex: 'FDD' },
  { band: '28', up_fr: [703, 748], up_earfcn: [27210, 27659], dl_fr: [758, 803], dl_earfcn: [9210, 9659], duplex: 'FDD' },
  { band: '29', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [717, 728], dl_earfcn: [9660, 9769], duplex: 'SDL' },
  { band: '30', up_fr: [2305, 2315], up_earfcn: [27660, 27759], dl_fr: [2350, 2360], dl_earfcn: [9770, 9869], duplex: 'FDD' },
  { band: '31', up_fr: [452.5, 457.5], up_earfcn: [27760, 27809], dl_fr: [462.5, 467.5], dl_earfcn: [9870, 9919], duplex: 'FDD' },
  { band: '32', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1452, 1496], dl_earfcn: [9920, 10359], duplex: 'SDL' },
  { band: '33', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1900, 1920], dl_earfcn: [36000, 36199], duplex: 'TDD' },
  { band: '34', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2010, 2025], dl_earfcn: [36200, 36349], duplex: 'TDD' },
  { band: '35', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1850, 1910], dl_earfcn: [36350, 36949], duplex: 'TDD' },
  { band: '36', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1930, 1990], dl_earfcn: [36950, 37549], duplex: 'TDD' },
  { band: '37', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1910, 1930], dl_earfcn: [37550, 37749], duplex: 'TDD' },
  { band: '38', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2570, 2620], dl_earfcn: [37750, 38249], duplex: 'TDD' },
  { band: '39', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1880, 1920], dl_earfcn: [38250, 38649], duplex: 'TDD' },
  { band: '40', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2300, 2400], dl_earfcn: [38650, 39649], duplex: 'TDD' },
  { band: '41', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2496, 2690], dl_earfcn: [39650, 41589], duplex: 'TDD' },
  { band: '42', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3400, 3600], dl_earfcn: [41590, 43589], duplex: 'TDD' },
  { band: '43', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3600, 3800], dl_earfcn: [43590, 45589], duplex: 'TDD' },
  { band: '44', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [703, 803], dl_earfcn: [45590, 46589], duplex: 'TDD' },
  { band: '45', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1447, 1467], dl_earfcn: [46590, 46789], duplex: 'TDD' },
  { band: '46', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [5150, 5925], dl_earfcn: [46790, 54539], duplex: 'TDD' },
  { band: '47', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [5855, 5925], dl_earfcn: [54540, 55239], duplex: 'TDD' },
  { band: '48', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3550, 3700], dl_earfcn: [55240, 56739], duplex: 'TDD' },
  { band: '49', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3550, 3700], dl_earfcn: [56740, 58239], duplex: 'TDD' },
  { band: '50', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1432, 1517], dl_earfcn: [58240, 59089], duplex: 'TDD' },
  { band: '51', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1427, 1432], dl_earfcn: [59090, 59139], duplex: 'TDD' },
  { band: '52', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3300, 3400], dl_earfcn: [59140, 60139], duplex: 'TDD' },
  { band: '53', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2483.5, 2495], dl_earfcn: [60140, 60254], duplex: 'TDD' },
  { band: '65', up_fr: [1920, 2010], up_earfcn: [131072, 131971], dl_fr: [2110, 2200], dl_earfcn: [65536, 66435], duplex: 'FDD' },
  { band: '66', up_fr: [1710, 1780], up_earfcn: [131972, 132671], dl_fr: [2110, 2200], dl_earfcn: [66436, 67335], duplex: 'FDD' },
  { band: '67', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [738, 758], dl_earfcn: [67336, 67535], duplex: 'SDL' },
  { band: '68', up_fr: [698, 728], up_earfcn: [132672, 132971], dl_fr: [753, 783], dl_earfcn: [67536, 67835], duplex: 'FDD' },
  { band: '69', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2570, 2620], dl_earfcn: [67836, 68335], duplex: 'SDL' },
  { band: '70', up_fr: [1695, 1710], up_earfcn: [132972, 133121], dl_fr: [1995, 2020], dl_earfcn: [68336, 68585], duplex: 'FDD' },
  { band: '71', up_fr: [663, 698], up_earfcn: [133122, 133471], dl_fr: [617, 652], dl_earfcn: [68586, 68935], duplex: 'FDD' },
  { band: '72', up_fr: [451, 456], up_earfcn: [133472, 133521], dl_fr: [461, 466], dl_earfcn: [68936, 68985], duplex: 'FDD' },
  { band: '73', up_fr: [450, 455], up_earfcn: [133522, 133571], dl_fr: [460, 465], dl_earfcn: [68986, 69035], duplex: 'FDD' },
  { band: '74', up_fr: [1427, 1470], up_earfcn: [133572, 134001], dl_fr: [1475, 1518], dl_earfcn: [69036, 69465], duplex: 'FDD' },
  { band: '75', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1432, 1517], dl_earfcn: [69466, 70315], duplex: 'SDL' },
  { band: '76', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1427, 1432], dl_earfcn: [70316, 70365], duplex: 'SDL' },
  { band: '85', up_fr: [698, 716], up_earfcn: [134002, 134181], dl_fr: [728, 746], dl_earfcn: [70366, 70545], duplex: 'FDD' },
  { band: '87', up_fr: [410, 415], up_earfcn: [134182, 134231], dl_fr: [420, 425], dl_earfcn: [70546, 70595], duplex: 'FDD' },
  { band: '88', up_fr: [412, 417], up_earfcn: [134232, 134281], dl_fr: [422, 427], dl_earfcn: [70596, 70645], duplex: 'FDD' }
];

const apn = [
  // 208 France
  { mcc_mnc: ["208-01", "208-02", "208-32", "208-91", "208-95"], operatore: "Orange S.A.", img: "https://www.orange.com/themes/theme_boosted/Master_Logo_RGB.png", },
  { mcc_mnc: ["208-03"], operatore: "MobiquiThings", img: "https://image.pitchbook.com/jyQfWPB5Paokc9gp0BTtkHm7pAK1567076979199_200x200", },
  { mcc_mnc: ["208-04"], operatore: "Societe d'ingenierie systeme telecom et reseaux", img: "", },
  { mcc_mnc: ["208-05", "208-06", "208-07"], operatore: "Globalstar Europe", img: "https://ww1.freelogovectors.net/wp-content/uploads/2022/09/globalstar-logo-freelogovectors.net_.png?lossy=1&w=2560&ssl=1", },
  { mcc_mnc: ["208-08", "208-09", "208-10", "208-11", "208-13"], operatore: "Altice", img: "https://alticefrance.com/sites/all/themes/zen_altice/logo.png", },
  { mcc_mnc: ["208-12"], operatore: "Truphone France", img: "https://mms.businesswire.com/media/20230222005599/it/1719919/2/logo.jpg", },
  { mcc_mnc: ["208-14"], operatore: "SNCF Réseau", img: "https://www.sncf-reseau.com/themes/custom/rzo/logo.svg", },
  { mcc_mnc: ["208-15", "208-16"], operatore: "Iliad", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Iliad_logo.svg/2560px-Iliad_logo.svg.png", },
  { mcc_mnc: ["208-17"], operatore: "Local Exchange Global Operation Services", img: "https://media.licdn.com/dms/image/C5616AQHLXyutEqSPJw/profile-displaybackgroundimage-shrink_200_800/0/1548321333681?e=2147483647&v=beta&t=X0Tm-tb8rNx_FXGIAVIy1-MDx9aLqtBCaW08r6CyC7g", },
  { mcc_mnc: ["208-18"], operatore: "Voxbone mobile", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Voxbone_2020_logo.svg/440px-Voxbone_2020_logo.svg.png", },
  { mcc_mnc: ["208-19"], operatore: "Haute-Garonne numérique", img: "https://hautegaronnenumerique.fr/wp-content/uploads/2019/10/logo-header.svg", },
  { mcc_mnc: ["208-20", "208-21"], operatore: "Bouygues Telecom", img: "https://www.bouyguestelecom.fr/static/wbm/assets/hub/img/logo-bouygues-telecom.svg", },
  { mcc_mnc: ["208-22"], operatore: "Transatel", img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Transatel_corporate_logo.png", },
  { mcc_mnc: ["208-23"], operatore: "Syndicat mixte ouvert Charente Numérique ", img: "https://www.charente-numerique.fr/wp-content/uploads/2018/12/charente_numerique_logo.png", },
  { mcc_mnc: ["208-24"], operatore: "MobiquiThings", img: "https://image.pitchbook.com/jyQfWPB5Paokc9gp0BTtkHm7pAK1567076979199_200x200", },
  { mcc_mnc: ["208-25"], operatore: "LycaMobile", img: "https://lycamobile-italy-website.s3.eu-central-1.amazonaws.com/lycamobile-it-cms/wp-content/uploads/2018/06/06101549/LM_CT_logo_it.svg", },
  { mcc_mnc: ["208-26"], operatore: "Euro-Information Telecom SAS", img: "https://cdnsi.e-i.com/INGR/sd/euro_information_2020/1.107.16/fr/images/css/env/logo.svg", },
  { mcc_mnc: ["208-27"], operatore: "Coriolis Telecom", img: "https://www.coriolis.com/skin/frontend/boilerplate/default/images/logo-coriolis-telecom.png", },
  { mcc_mnc: ["208-28"], operatore: "Airbus Defence and Space SAS", img: "https://www.airbus.com/themes/custom/airbus_web_experience_ui/logo.svg", },
  { mcc_mnc: ["208-29"], operatore: "Cubic télécom France", img: "https://www.cubictelecom.com/wordpress/wp-content/uploads/2021/01/cubic-logo-1.svg", },
  { mcc_mnc: ["208-30"], operatore: "Syma Mobile", img: "https://www.symamobile.com/version-202105/images/logo_syma.svg?v=20230902_01", },
  { mcc_mnc: ["208-31"], operatore: "Mundio Mobile", img: "https://media.licdn.com/dms/image/C560BAQEM1SmbwSTniw/company-logo_200_200/0/1519908762955?e=2147483647&v=beta&t=iVF1tS2U2w6qPknbaPYot9iJdnaJg7eyZtN64tpPDeE", },
  { mcc_mnc: ["208-33"], operatore: "Département des Pyrénées-Atlantiques", img: "https://upload.wikimedia.org/wikipedia/fr/thumb/e/e1/Logo_Pyr%C3%A9n%C3%A9es_Atlantiques_2015.svg/langfr-1024px-Logo_Pyr%C3%A9n%C3%A9es_Atlantiques_2015.svg.png", },
  { mcc_mnc: ["208-34"], operatore: "Cellhire France", img: "https://www.cellhire.fr/img/logos/cellhire-dark.svg", },
  { mcc_mnc: ["208-50"], operatore: "EDF", img: "https://www.edf.fr/themes/custom/nova/assets/images/00-tokens/logos/images/logo-edf.svg", },
  { mcc_mnc: ["208-70"], operatore: "Weaccess group", img: "https://www.weaccess.fr/img/logo.png", },
  { mcc_mnc: ["208-86"], operatore: "SEM@FOR77", img: "", },
  { mcc_mnc: ["208-87"], operatore: "Régie autonome des transports parisiens", img: "https://www.ratp.fr/sites/default/files/logos/logo_ratp_2.svg", },
  { mcc_mnc: ["208-88"], operatore: "Bouygues Telecom", img: "https://www.bouyguestelecom.fr/static/wbm/assets/hub/img/logo-bouygues-telecom.svg", },
  { mcc_mnc: ["208-89"], operatore: "Fondation b-com", img: "https://b-com.com/assets/images/logos/b-com.svg", },
  { mcc_mnc: ["208-90"], operatore: "Images & Réseaux", img: "https://www.images-et-reseaux.com/voy_content/uploads/2021/11/logo_ir_100.png.png", },
  { mcc_mnc: ["208-92"], operatore: "Association Plate-forme Télécom", img: "", },
  { mcc_mnc: ["208-93", "208-97"], operatore: "Thales Communications & Security SAS", img: "http], //coopol.eurecom.fr/sites/coopol.eurecom.fr/files/Logo_COOPOL.jpg", },
  { mcc_mnc: ["208-94"], operatore: "Halys", img: "https://static.wixstatic.com/media/75b555_20ded2b2cf3b4543baadc03ff7a0b3ec~mv2.jpg/v1/fill/w_412,h_206,al_c,lg_1,q_80,enc_auto/75b555_20ded2b2cf3b4543baadc03ff7a0b3ec~mv2.jpg", },
  { mcc_mnc: ["208-96"], operatore: "Région Bourgogne-Franche-Comté", img: "https://www.bourgognefranchecomte.fr/themes/bourg/logo.svg", },
  { mcc_mnc: ["208-98"], operatore: "Société Air France", img: "https://les-eauxvives.org/wp-content/uploads/2016/09/air-france.png", },
  // 525 Singapore
  { mcc_mnc: ["525-07", "525-01", "525-02"], operatore: "Singapore Telecom", img: "https://cdn1.singteldigital.com/content/dam/singtel/online-draft/singtel_logo_coloured.png", },
  { mcc_mnc: ["525-11", "525-011", "525-03"], operatore: "M1 limited", img: "https://www.m1.com.sg/-/media/M1/M1CP/Feature/Identity/Logo/logo.png?h=95&w=95&la=en&hash=91F81D0822B2265764A0E2FDDB2CA2915B9B0422", },
  { mcc_mnc: ["525-05", "525-06", "525-08"], operatore: "StarHub Mobile", img: "https://www.starhub.com/etc/designs/starhub/clientlibs/img/agile/star-icon.png", },
  { mcc_mnc: ["525-09"], operatore: "Liberty Wireless Pte Ltd", img: "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/73/4f/f3/734ff3c7-3b8b-5c88-8307-c29a23308cf0/AppIcon-1x_U007emarketing-0-5-0-85-220.png/1024x1024.jpg", },
  { mcc_mnc: ["525-10"], operatore: "TPG Telecom Pte Ltd", img: "https://www.tpgtelecom.com.au/themes/custom/tpg/TPG_Telecom_Logo_RGB.png", },
  { mcc_mnc: ["525-12"], operatore: "GRID Communications Pte Ltd.", img: "https://www.grid.net.sg/wp-content/uploads/2019/01/logo-final.png", },
  // 502 Malesia
  { mcc_mnc: ["502-01", "502-11"], operatore: "Telekom Malaysia Bhd", img: "https://tm.com.my/themes/custom/tmtheme/img/TM_YNIN.webp", },
  { mcc_mnc: ["502-10"], operatore: "Maxis, DiGi, Celcom, XOX", img: "https://www.maxis.com.my/content/dam/mxs/images/rebrand/about-maxis/newsroom/2021/march/tron-social.png", },
  { mcc_mnc: ["502-12", "502-17"], operatore: "Maxis Communications Berhad", img: "https://www.maxis.com.my/content/dam/mxs/rebrand/maxis-logo.png", },
  { mcc_mnc: ["502-13"], operatore: "Celcom Axiata Berhad", img: "https://www.celcom.com.my/sites/default/files/menu_icons/personal-logo_3_0.svg", },
  { mcc_mnc: ["502-14"], operatore: "Telekom Malaysia Berhad for PSTN SMS", img: "https://tm.com.my/themes/custom/tmtheme/img/TM_YNIN.webp", },
  { mcc_mnc: ["502-150"], operatore: "Tune Talk Sdn Bhd", img: "https://cdn.tunetalk.com/wp-content/uploads/2022/02/27132634/tunetalk-icon.png", },
  { mcc_mnc: ["502-151"], operatore: "Baraka Telecom Sdn Bhd", img: "", },
  { mcc_mnc: ["502-152"], operatore: "YTL Communications Sdn Bhd", img: "https://www.ytl.com/images/ytl-logo_black.png", },
  { mcc_mnc: ["502-153"], operatore: "Webe Digital Sdn Bhd", img: "https://upload.wikimedia.org/wikipedia/commons/d/df/Webe-with-TM-Group_01.png?20170731020907", },
  { mcc_mnc: ["502-154"], operatore: "Talk Focus Sdn Bhd", img: "", },
  { mcc_mnc: ["502-155"], operatore: "Clixster Mobile Sdn Bhd", img: "https://1.bp.blogspot.com/-uo738emOxD4/Un5nH37oZVI/AAAAAAAAABY/WWuvS-ZtZOc/s200/logo.png", },
  { mcc_mnc: ["502-156"], operatore: "Altel Communications Sdn Bhd", img: "https://www.altel.my/images/logo.png", },
  { mcc_mnc: ["502-157"], operatore: "Telekomunikasi Indonesia International (M) Sdn Bhd", img: "https://telin.net/images/logo.svg", },
  { mcc_mnc: ["502-16"], operatore: "DiGi Telecommunications", img: "https://assets-global.website-files.com/6152b2d34ca06b6d275dd66e/641a9eefcec88a2cf7a55602_Digi_CelcomDigi-Logo.png", },
  { mcc_mnc: ["502-18"], operatore: "U Mobile Sdn Bhd", img: "https://www.u.com.my/content/dam/u-mobile/personal/general/logo/umobile-personal.svg", },
  { mcc_mnc: ["502-19"], operatore: "Celcom Axiata Berhad", img: "https://www.celcom.com.my/sites/default/files/menu_icons/personal-logo_3_0.svg", },
  { mcc_mnc: ["502-20"], operatore: "Electcoms Berhad", img: "https://electcoms.net.my/wp-content/uploads/2023/05/logo-electcoms.svg", },      
  // 230 Repubblica Ceca
  { mcc_mnc: ["230-01"], operatore: "T-Mobile", img: "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg", },
  { mcc_mnc: ["230-02"], operatore: "O2", img: "https://static2.o9.de/resource/blob/59652/2943a5dae8568adfa7f8acf11b14e09d/png/telefonica-logo-image-picture-data.webp", },
  { mcc_mnc: ["230-04"], operatore: "Nordic Telecom s.r.o.", img: "https://aeqqktywno.cloudimg.io/v7/_atmimg_prod_/images/profiles/company-2937018/f40defa0-d172-40f0-ba7a-24eab9f83c6b.svg", },
  { mcc_mnc: ["230-05"], operatore: "PODA a.s.", img: "https://media.licdn.com/dms/image/D4E0BAQHVHspSIXDchw/company-logo_200_200/0/1681742275515?e=2147483647&v=beta&t=vpwTVJHf9S-r31kAyvnB9gP4HdfUdiYuHVVXaonJNxY", },
  { mcc_mnc: ["230-06"], operatore: "Nordic Telecom 5G a.s.", img: "https://i.iinfo.cz/images/235/NT_2.jpg", },
  { mcc_mnc: ["230-07"], operatore: "ASTELNET, s.r.o.", img: "http], //www.astelnet.com/files/bluemarine_logo.png", },
  { mcc_mnc: ["230-08"], operatore: "Compatel s.r.o.", img: "http], //www.compatel.com/public/images/logo.png", },
  { mcc_mnc: ["230-09"], operatore: "Vectone Mobile", img: "https://www.vectonemobile.co.uk/sites/default/files/logo_2.png", },
  { mcc_mnc: ["230-98"], operatore: "Správa železniční dopravní cesty, s.o.", img: "https://www.spravazeleznic.cz/szdc-theme-web/images/szdc/szdc-logo-barevne.svg", },      
  // 222 Italia
  { mcc_mnc: ["222-37", "222-88"], operatore: "Wind Tre", img: "https://d1qsjop7tycsfb.cloudfront.net/typo3conf/ext/wind_onebrand/Resources/Public/img/mm_header/windtre-logo-mob.png", },
  { mcc_mnc: ["222-99"], operatore: "3 Italia", img: "https://d1qsjop7tycsfb.cloudfront.net/typo3conf/ext/wind_onebrand/Resources/Public/img/mm_header/windtre-logo-mob.png", },
  { mcc_mnc: ["222-34"], operatore: "BT Italia", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/BT_logo_2019.svg/800px-BT_logo_2019.svg.png", },
  { mcc_mnc: ["222-53"], operatore: "COOP Voce", img: "https://www.coopvoce.it/etc.clientlibs/coopvoce/clientlibs/clientlib-site/resources/images/logo-coopvoce.svg", },
  { mcc_mnc: ["222-08"], operatore: "Fastweb S.p.A.", img: "https://upload.wikimedia.org/wikipedia/it/archive/4/42/20230104192916%21Fastweb_logo_flat.png", },
  { mcc_mnc: ["222-50"], operatore: "Iliad", img: "https://www.iliad.it/assets/images/logo.png", },
  { mcc_mnc: ["222-33"], operatore: "Poste Mobile S.p.A.", img: "http], //www.postemobile.it/ClientLibrary/img/share/logo-postemobile.png", },
  { mcc_mnc: ["222-39"], operatore: "SMS Italia S.r.l.", img: "https://www.sms.it/user/themes/smsh/assets/logo_smsit_o_from.png", },
  { mcc_mnc: ["222-47"], operatore: "Tiscali S.p.A.", img: "https://casa.tiscali.it/img/logo.png", },
  { mcc_mnc: ["222-01", "222-43", "222-48"], operatore: "Tim", img: "https://risorse.tim.it/content/dam/flytoco-areapubblica-aemfe/loghi/logo.svg", },
  { mcc_mnc: ["222-10", "222-06", "214-01", "214-06", "214-18", "214-24", "214-37", "268-01", "268-91", "230-03", "230-99"], operatore: "Vodafone", img: "https://privati.vodafone.it/favicon-32x32.png", },
  { mcc_mnc: ["222-38"], operatore: "Linkem S.p.A.", img: "https://www.linkem.com/-/media/linkem/images/logo/logo-linkem-new.png?h=140&w=320&hash=7F4116801FB8D7C2C4C5E58256B123CBA8153816", },
  // 214 Spagna
  { mcc_mnc: ["214-02"], operatore: "Alta Tecnologia en Comunicacions SL", img: "https://www.altecom.net/images/logo-altecom.jpg", },
  { mcc_mnc: ["214-03", "214-09", "214-19", "214-21"], operatore: "Orange Espagne", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Orange_logo.svg/150px-Orange_logo.svg.png", },
  { mcc_mnc: ["214-04", "214-23", "214-29", "214-33"], operatore: "Xfera Moviles", img: "https://upload.wikimedia.org/wikipedia/commons/6/66/Yoigo_morado.svg", },
  { mcc_mnc: ["214-05", "214-07", "214-32", "214-38"], operatore: "Telefónica Móviles España", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Movistar_isotype_2020.svg/1280px-Movistar_isotype_2020.svg.png", },
  { mcc_mnc: ["214-08"], operatore: "Euskaltel", img: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Euskaltel_2018_logo.svg", },
  { mcc_mnc: ["214-11"], operatore: "Telecom Castilla-La Mancha", img: "https://telecomclm.net/wp-content/uploads/2019/04/cropped-operador-2.png", },
  { mcc_mnc: ["214-13"], operatore: "Syma Mobile España", img: "https://www.symamobile.com/version-202001/images/logo_syma.svg", },
  { mcc_mnc: ["214-16", "214-17"], operatore: "R Cable y Telecomunicaciones Galicia S.A.", img: "https://en.wikipedia.org/wiki/File], R_Cable_logo.svg", },
  { mcc_mnc: ["214-20"], operatore: "Fonyou Telecom", img: "https://fonyou.com/wp-content/themes/beep-fonyou/assets/images/logos/footer_logo.svg", },
  { mcc_mnc: ["214-25", "268-04", "311-960"], operatore: "LycaMobile", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Lycamobile.svg/1920px-Lycamobile.svg.png", },
  { mcc_mnc: ["214-26"], operatore: "Lleida Networks Serveis Telemátics, SL", img: "https://www.lleida.net/img/logos/logo-lleidanet.png", },
  { mcc_mnc: ["214-27"], operatore: "SCN Truphone, S.L.", img: "https://encrypted-tbn2.gstatic.com/images?q=tbn], ANd9GcRV4n6Y6UlLkNeXgq5Rczv0TTT3rBDC0mNuQvowIg9oTPfaz-jJ", },
  { mcc_mnc: ["214-28"], operatore: "Consorcio de Telecomunicaciones Avanzadas, S.A.", img: "http], //www.cotatelecom.com/wp-content/uploads/2016/11/Logo-transparente.png", },
  { mcc_mnc: ["214-30", "268-11"], operatore: "Compatel Limited", img: "", },
  { mcc_mnc: ["214-31"], operatore: "Red Digital De Telecomunicacioned de las lslas Baleares", img: "", },
  { mcc_mnc: ["214-34"], operatore: "Aire Networks del Mediterráneo", img: "", },
  { mcc_mnc: ["214-35"], operatore: "INGENIUM OUTSOURCING SERVICES", img: "", },
  { mcc_mnc: ["214-36"], operatore: "ALAI OPERADOR DE TELECOMUNICACIONES", img: "https://www.hostelvending.com/image/sys_companies/700x0/alai-operador-de-telecomunicaciones-s.l.png", },
  { mcc_mnc: ["214-51"], operatore: "Administrador de Infraestructuras Ferroviarias", img: "https://www.adif.es/o/adif-theme/images/color_schemes/adif/logo-adif.svg", },
  // 424 Emirati Arabi
  { mcc_mnc: ["424-02"], operatore: "Emirates Telecom Corp", img: "https://www.etisalat.ae/content/dam/etisalat/consumer/nwt/mega-menu/etisalat-logo/etisalat-logo.svg", },
  { mcc_mnc: ["424-03"], operatore: "Emirates Integrated Telecommunications Company", img: "https://www.du.ae/servlet/duaediscovery/common/discovery/common/images/about-us-footer.svg", },      
  // 268 Portogallo
  { mcc_mnc: ["268-02", "268-06", "268-80"], operatore: "Telecomunicações Móveis Nacionais", img: "https://conteudos.meo.pt/Style%20Library/consumo/images/logo_meo_preto.png", },
  { mcc_mnc: ["268-03"], operatore: "NOS Comunicações", img: "https://galeria.nos.pt/Style%20Library/5G/images/logo-nos.svg", },
  { mcc_mnc: ["268-05"], operatore: "Oniway - Inforcomunicaçôes, S.A.", img: "https://en.wikipedia.org/wiki/File], Oni_logo2.png", },
  { mcc_mnc: ["268-07"], operatore: "Mundio Mobile (Portugal) Limited", img: "https://www.vectonemobile.co.uk/sites/default/files/logo_2.png", },
  { mcc_mnc: ["268-12"], operatore: "Infraestruturas de Portugal, S.A.", img: "https://www.infraestruturasdeportugal.pt/themes/ip_corporativo_theme/images/logo-cor.png", },
  { mcc_mnc: ["268-13"], operatore: "G9Telecom, S.A.", img: "https://www.g9telecom.pt/img/logo.png", },
  { mcc_mnc: ["268-21"], operatore: "Zapp Portugal", img: "", },
  // 334 Messico
  { mcc_mnc: ["334-01", "334-04", "334-05", "334-09", "334-10", "334-40", "334-50", "334-90"], operatore: "AT&T Mexico", img: "	https://www.att.com.mx/content/dam/ATT/personal/lading/home-assets/marquee/homer/menu/logo-att.svg", },
  { mcc_mnc: ["334-02", "334-20"], operatore: "Telcel", img: "https://www.telcel.com/content/dam/htmls/img/icons/logo-telcel.svg", },
  { mcc_mnc: ["334-03", "334-30", "334-45"], operatore: "Movistar", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Movistar_isotype_2020.svg/1280px-Movistar_isotype_2020.svg.png", },
  { mcc_mnc: ["334-60"], operatore: "Servicios de Acceso Inalambrico, S.A. de C.V.", img: "", },
  { mcc_mnc: ["334-66"], operatore: "Telmex", img: "https://telmex.com/documents/20194/7582410/telmex.svg/fd1183b3-c08d-7c40-86f0-cec5dd75c6d7", },
  { mcc_mnc: ["334-70", "334-80"], operatore: "Unefon", img: "https://www.unefon.com.mx/img/unefon-logo-black.png", },
  { mcc_mnc: ["334-140"], operatore: "Altán Redes S.A.P.I. de C.V.", img: "https://cdn-www.bnamericas.com/img/logo_bna.png", },
  { mcc_mnc: ["334-299"], operatore: "Carriers de otros paises", img: "", },
  { mcc_mnc: ["334-999"], operatore: "FIX LINE", img: "", },
  // 313 United States of America
  { mcc_mnc: ["313-100", "313-110", "313-190"], operatore: "700 MHz Public Safety Broadband", img: "https://www.firstnet.gov/sites/default/files/FirstNetAuthorityLogo.jpg", },      
  // 312 Stati Uniti d'America
  { mcc_mnc: ["312-500"], operatore: "AB Spectrum LLC", img: "", },
  { mcc_mnc: ["312-70"], operatore: "Adams Networks Inc", img: "", },
  { mcc_mnc: ["310-880"], operatore: "Advantage Cellular Systems, Inc.", img: "https://www.dtcwireless.com/wp-content/uploads/2017/01/logo-1.jpg", },
  { mcc_mnc: ["310-850"], operatore: "Aeris Communications, Inc.", img: "https://www.aeris.com/wp-content/uploads/2022/01/aeris-logo.svg", },
  { mcc_mnc: ["310-630"], operatore: "Agri-Valley Communications", img: "https://ipid72.p3cdn1.secureserver.net/wp-content/uploads/2022/01/agri-valley-services-logo.png", },
  { mcc_mnc: ["310-34"], operatore: "Airpeak", img: "", },
  { mcc_mnc: ["310-50", "310-190"], operatore: "Alaska Communications", img: "https://www.alaskacommunications.com/-/media/Images/AKCS_Global/akcs-logo-expect-more.ashx?h=100&w=190&la=en&hash=9C834A2C8A68163098BC405BD9D0C99B", },
  { mcc_mnc: ["312-880"], operatore: "Albemarle County Public Schools", img: "", },
  { mcc_mnc: ["310-54"], operatore: "Alltel US", img: "", },
  { mcc_mnc: ["313-390"], operatore: "Altice USA Wireless, Inc.", img: "https://alticeusadev2.prod.acquia-sites.com/sites/default/files/2022-05/ALTICE_LOGO_HORIZONTAL_REV_TRANS_RGB.png", },      
  // 311 Stati uniti d'America
  { mcc_mnc: ["311-770"], operatore: "Altiostar Networks, Inc.", img: "", },
  { mcc_mnc: ["311-780"], operatore: "American Samoa Telecommunications", img: "https://www.astca.net/wp-content/uploads/2018/08/Logo.png", },
  { mcc_mnc: ["311-30"], operatore: "Americell PA 3 Partnership", img: "https://www.indigowireless.com/uploads/7/0/1/7/70179849/indigo-logo.jpg", },
  { mcc_mnc: ["311-200"], operatore: "ARINC", img: "https://it.wikipedia.org/wiki/File], ARINC_logo.svg", },
  { mcc_mnc: ["312-750"], operatore: "Artemis Networks LLC", img: "https://assets.website-files.com/52f04b50d6dbc2930f000490/622598a5639e30842ea2bdc2_artemis_title_home%402x.png", },
  { mcc_mnc: ["313-440"], operatore: "Arvig Enterprises, Inc.", img: "https://www.arvig.com/wp-content/uploads/2018/11/arvig_logo.png", },
  { mcc_mnc: ["310-16", "310-30", "310-70", "310-80", "310-90", "310-150", "310-170", "310-280", "310-380", "310-410", "310-560", "310-670", "310-680", "310-950", "311-70", "311-90", "311-180", "311-190", "312-90", "312-670", "312-680", "313-210"], operatore: "AT&T Mobility", img: "http], //t0.gstatic.com/images?q=tbn], ANd9GcRoyThCKtMvxaPQrZ0aNYDoBbTs6m-aQprv47XhaSuynSmsSZon", },
  { mcc_mnc: ["311-570"], operatore: "Bend Cable Communications LLC", img: "https://hellotds.com/content/dam/tdstelecom/images/logos/tds-logo.svg", },
  { mcc_mnc: ["311-970"], operatore: "Big River Broadband, LLC", img: "https://residential.bigrivercom.com/wp-content/uploads/br-logo.png", },
  { mcc_mnc: ["313-270"], operatore: "Blackstar Management", img: "", },
  { mcc_mnc: ["311-440", "311-800", "311-810"], operatore: "Bluegrass Cellular LLC", img: "https://www.verizon.com/about/sites/default/files/2021-03/bluegrass-cellular-2-700x393.jpg", },
  { mcc_mnc: ["544-11"], operatore: "Bluesky", img: "https://blueskyweb.org/images/logo-32x32.jpg", },
  { mcc_mnc: ["312-810"], operatore: "Bristol Bay Telephone Cooperative", img: "https://www.bristolbay.com/uploads/8/2/1/8/82184268/published/bbtc-logo-for-website.jpg?1609437968", },
  { mcc_mnc: ["312-540"], operatore: "Broadband In Hand LLC", img: "", },
  { mcc_mnc: ["312-570"], operatore: "Blue Wireless", img: "", },
  { mcc_mnc: ["311-330"], operatore: "Bug Tussel Wireless LLC", img: "https://btussel.com/wp-content/themes/webfitters/assets/images/logo.png", },
  { mcc_mnc: ["310-900", "312-450"], operatore: "Cable & Communications Corporation", img: "https://cable-com.com/wp-content/uploads/2017/10/cci_script_logo_sm.jpg", },
  { mcc_mnc: ["313-310"], operatore: "CAL.NET, Inc.", img: "https://www.calnet.com/public/img/calnet_logo.png", },
  { mcc_mnc: ["313-300"], operatore: "Cambio WiFi of Delmarva, LLC", img: "", },
  { mcc_mnc: ["313-20"], operatore: "Cambridge Telephone Company Inc.", img: "https://ctcweb.net/Portals/0/Logo_Vector.png?ver=2017-10-04-120534-490", },
  { mcc_mnc: ["310-130", "312-470"], operatore: "Carolina West Wireless", img: "", },
  { mcc_mnc: ["310-360", "312-270", "312-280"], operatore: "Cellular Network Partnership", img: "https://gopioneer.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-dark.02d7e1b2.png&w=128&q=75", },
  { mcc_mnc: ["311-230", "311-630"], operatore: "Cellular South Inc.", img: "https://en.wikipedia.org/wiki/File], Cspire-logo.png", },
  { mcc_mnc: ["312-260"], operatore: "Central LTE Holdings", img: "", },
  { mcc_mnc: ["313-330"], operatore: "CenturyTel Broadband Services LLC", img: "", },
  { mcc_mnc: ["311-10", "312-220"], operatore: "Chariton Valley Communications", img: "https://www.cvalley.net/wp-content/uploads/2016/08/logo.svg", },
  { mcc_mnc: ["310-420"], operatore: "Cincinnati Bell Wireless", img: "https://login.altafiber.com/cinbell-logo-color.png", },
  { mcc_mnc: ["312-890"], operatore: "Circle Gx", img: "https://a-cloud.b-cdn.net/media/iW=210&iH=210&oX=2&oY=0&cW=206&cH=210/9d4c4988b9c846c1b5c03ad43957daf8.png", },
  { mcc_mnc: ["312-800"], operatore: "Cirrus Core Networks", img: "", },
  { mcc_mnc: ["315-10"], operatore: "Citizens Broadband Radio Service", img: "", },
  { mcc_mnc: ["312-310"], operatore: "Clear Stream Communications, LLC", img: "https://clearstreamcomms.com/wp-content/uploads/2016/11/Clearstream-logo.png", },
  { mcc_mnc: ["312-100"], operatore: "ClearSky Technologies, Inc.", img: "http], //www.csky.com/wp-content/uploads/2018/08/ClearSkyTechnologies_twotone_v2-250x100.jpg", },
  { mcc_mnc: ["312-140"], operatore: "Cleveland Unlimited, Inc.", img: "https://en.wikipedia.org/wiki/File], Revol_logo_new.png", },
  { mcc_mnc: ["311-790"], operatore: "Coleman County Telephone Cooperative, Inc.", img: "https://www.cctelco.org/wp-content/uploads/2017/04/logo-white-small.png", },
  { mcc_mnc: ["311-40", "311-320", "312-370", "311-550"], operatore: "Commnet Wireless", img: "https://www.commnetbroadband.com/uploads/1/3/9/6/139656345/published/commnetlogo-horizontalwbg.png?1643041259", },
  { mcc_mnc: ["310-60", "312-440"], operatore: "Consolidated Telcom", img: "https://consolidatednd.com/wp-content/uploads/2022/06/ConsLogoVector-01-NO-TAGLINE-768x198.png", },
  { mcc_mnc: ["310-930", "312-380"], operatore: "Copper Valley Wireless", img: "https://ruralwireless.org/wp-content/uploads/2010/09/Copper-Valley-Wireless-logo.jpeg", },
  { mcc_mnc: ["311-240"], operatore: "Cordova Wireless", img: "https://www.ctcak.net/wp-content/uploads/2021/01/CWC-solid-background-980x457.jpg", },
  { mcc_mnc: ["313-60"], operatore: "Country Wireless", img: "https://countrywireless.com/wp-content/uploads/2016/11/cropped-admin-ajax-1-2.png", },
  { mcc_mnc: ["312-60"], operatore: "CovarageCo", img: "", },
  { mcc_mnc: ["311-140"], operatore: "Cross Telephone Company", img: "https://www.crosstel.net/images/logos/2022-OCT-logo-01.png", },
  { mcc_mnc: ["310-700"], operatore: "Cross Valiant Cellular Partnership", img: "", },
  { mcc_mnc: ["312-30", "312-10"], operatore: "Cross Wireless", img: "https://bravadowireless.com/wp-content/themes/theme-bravado/assets/images/Bravado_Logo_White.png", },
  { mcc_mnc: ["312-40", "312-950", "313-220"], operatore: "Custer Telephone Co-op (CTCI)", img: "https://custertel.net/wp-content/uploads/2021/08/cropped-FullColor.png", },
  { mcc_mnc: ["310-780"], operatore: "Dispatch Direct", img: "https://dct.aero/wp-content/uploads/2020/02/dd-logo-mail.svg", },
  { mcc_mnc: ["313-340", "313-350", "313-360"], operatore: "Dish Network", img: "https://it.wikipedia.org/wiki/File], Dish_Network_2019.svg", },
  { mcc_mnc: ["313-30"], operatore: "Eagle Telephone System Inc.", img: "", },
  { mcc_mnc: ["310-750", "312-120", "312-130", "312-910"], operatore: "East Kentucky Network, LLC", img: "https://www.appalachianwireless.com/images/AppalachianWirelessLogo.png", },
  { mcc_mnc: ["311-760"], operatore: "Edigen Inc.", img: "", },
  { mcc_mnc: ["311-460"], operatore: "Electric Imp Inc.", img: "", },
  { mcc_mnc: ["310-610"], operatore: "Elkhart Telephone Co.", img: "", },
  { mcc_mnc: ["312-410"], operatore: "Eltopia Communications, LLC", img: "http], //wp.eltopia.com/wp-content/uploads/2014/09/eltopia-sm.jpg", },
  { mcc_mnc: ["311-950"], operatore: "Enhanced Telecommmunications Corp.", img: "https://www.etd.com/wp-content/uploads/2018/03/ETD-Logo-1.jpg", },
  { mcc_mnc: ["310-35"], operatore: "ETEX Communications, LP", img: "https://www.etex.net/wp-content/uploads/2015/07/etex-3d-logo-1.svg", },
  { mcc_mnc: ["310-990"], operatore: "Evolve Cellular Inc.", img: "https://uploads-ssl.webflow.com/6234b7acf63b34064d6110fb/6234c1c7f5197a4f5d35d9d9_Evolve%20Cellular%20Logo%20White%20on%20Transparent%20Background%20(small).png", },
  { mcc_mnc: ["313-260"], operatore: "Expeto Wireless Inc.", img: "", },
  { mcc_mnc: ["310-311"], operatore: "Farmers Wireless", img: "", },
  { mcc_mnc: ["313-520"], operatore: "Florida Broadband, Inc.", img: "https://lightyear.ai/images/home/lightyear-logo-white.svg", },
  { mcc_mnc: ["312-390"], operatore: "FTC Communications LLC", img: "https://www.ftc.ch/wp-content/uploads/ressources/logo_ftc.svg", },
  { mcc_mnc: ["312-50"], operatore: "Fuego Wireless", img: "", },
  { mcc_mnc: ["313-240"], operatore: "Fundamental Holdings, Corp.", img: "", },
  { mcc_mnc: ["310-430"], operatore: "GCI Communications Corp.", img: "https://www.gci.com/-/media/images/gci/global/header/gci_logo_same_dimension_bottom_aligned.png?h=60&iar=0&mh=60&w=80&hash=29DD92F2DCE81D0F33229F43F758E2A9", },
  { mcc_mnc: ["311-370"], operatore: "General Communication Inc.", img: "http], //www.generalcommunicationsrl.it/wp-content/uploads/2016/05/logo-trasparente-general-communication.gif", },
  { mcc_mnc: ["311-900", "312-870"], operatore: "GigSky", img: "https://static.wixstatic.com/media/58c0c3_4295422bbcf948a083c3f67019d053a9~mv2.png/v1/crop/x_0,y_0,w_553,h_123/fill/w_296,h_70,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/gs_banner_white_logo.png", },
  { mcc_mnc: ["312-790"], operatore: "Gila Electronics", img: "http], //gilaelectronics.com/wp-content/uploads/2017/10/GE-white-clear.png", },
  { mcc_mnc: ["310-970"], operatore: "Globalstar", img: "https://www.globalstar.com/Globalstar/media/Globalstar/Images/Logos/GlobalstarEmblemLogo.png", },
  { mcc_mnc: ["311-890"], operatore: "Globecomm Network Services Corporation", img: "", },
  { mcc_mnc: ["312-620"], operatore: "GlobeTouch Inc.", img: "https://www.airlinq.com/wp-content/uploads/2020/03/PNG-FINAL-EXPORT-1-1536x1070-1.png", },
  { mcc_mnc: ["312-710"], operatore: "Great North Woods Wireless LLC", img: "", },
  { mcc_mnc: ["312-550"], operatore: "Great Plains Communications, Inc.", img: "	https://gpcom.com/wp-content/uploads/2019/06/gpc-logo-1000.svg", },
  { mcc_mnc: ["310-33"], operatore: "Guam Telephone Authority", img: "Guam Telephone Authority", },
  { mcc_mnc: ["311-680"], operatore: "GreenFly LLC", img: "https://www.greenfly.com/wp-content/themes/greenfly/assets/images/greenfly-logo.svg#logo", },
  { mcc_mnc: ["313-290"], operatore: "Gulf Coast Broadband LLC", img: "	https://vportal.visp.net/gulfcoastwifi/wp-content/…/sites/137/2017/09/Web_Size_Transparent_Bkgrd.png", },
  { mcc_mnc: ["312-930"], operatore: "Hewlett-Packard Communication Services  LLC", img: "", },
  { mcc_mnc: ["310-540"], operatore: "Hilliary Communications", img: "	https://hilliary.com/wp-content/uploads/2022/08/hill_logo_square.png", },
  { mcc_mnc: ["313-420"], operatore: "Hudson Valley Wireless", img: "https://www.hvwisp.com/wp-content/uploads/2021/02/cropped-HVW-Logo-400x100-Trans-1.png", },
  { mcc_mnc: ["311-340"], operatore: "Illinois Valley Cellular", img: "https://i0.wp.com/www.ivcel.com/wp-content/uploads/2019/06/ivc_newweblogo.png?fit=800%2C168&ssl=1", },
  { mcc_mnc: ["313-250"], operatore: "Imperial County Office of Education", img: "https://www.icoe.org/themes/icoe/icoe-logo.png", },
  { mcc_mnc: ["312-20"], operatore: "Infrastructure Networks  LLC", img: "https://inetlte.com/wp-content/themes/inet/img/inet.svg", },
  { mcc_mnc: ["310-580"], operatore: "Inland Cellular Telephone Company", img: "https://inlandcellular.com/wp-content/uploads/2020/07/IC-Logo-Flat-large.png", },
  { mcc_mnc: ["312-970"], operatore: "IOSAZ Intellectual Property LLC", img: "", },
  { mcc_mnc: ["311-410", "312-170"], operatore: "Iowa RSA No. 2 LP", img: "	https://rsaorg.com/wp-content/uploads/2017/10/RSALogo_SmallDark.png", },
  { mcc_mnc: ["310-770", "310-530"], operatore: "Iowa Wireless Services LLC", img: "	https://upload.wikimedia.org/wikipedia/en/thumb/b/bf/IWireless_logo.png/220px-IWireless_logo.png", },
  { mcc_mnc: ["310-300"], operatore: "iSmart Mobile  LLC", img: "", },
  { mcc_mnc: ["310-32"], operatore: "IT&E Overseas  Inc", img: "https://store.ite.net/wp-content/uploads/2020/09/ITE-LOGO.png", },
  { mcc_mnc: ["310-920"], operatore: "James Valley Wireless  LLC", img: "https://jamesvalley.com/wp-content/uploads/2021/03/jvcameraready_black_web.png", },
  { mcc_mnc: ["310-650"], operatore: "Jasper Technologies", img: "", },
  { mcc_mnc: ["310-870"], operatore: "Kaplan Telephone Company", img: "https://cdn.shopify.com/s/files/1/2078/7729/files/kaptellogowithwebsite3_550x.png?v=1613733865", },
  { mcc_mnc: ["312-740"], operatore: "KDDI America  Inc.", img: "https://us.kddi.com/files/user/common/img/header/KDDI_America.png", },
  { mcc_mnc: ["312-460"], operatore: "Ketchikan Public Utilities (KPU)", img: "", },
  { mcc_mnc: ["313-280"], operatore: "King Street Wireless  LP", img: "https://broadbandnow.com/images/King-Street-Wireless-5727.png", },
  { mcc_mnc: ["312-830"], operatore: "Kings County Office of Education", img: "https://www.kingscoe.org/cms/lib/CA50000415/Centri…late/GlobalAssets/images///logos/KCOE_280x280.png", },
  { mcc_mnc: ["311-310"], operatore: "Leaco Rural Telephone Company Inc.", img: "https://img1.wsimg.com/isteam/ip/44d07865-8641-4d93-8ef6-53f716be49c9/Capture-35d86a1.PNG/], /rs=w], 481,h], 200,cg], true,m/cr=w], 481,h], 200/qt=q], 95", },
  { mcc_mnc: ["311-510", "311-130", "311-160", "311-520"], operatore: "Lightsquared L.P.", img: "https://en.wikipedia.org/wiki/File], Ligado_Networks_logo.png", },
  { mcc_mnc: ["311-980"], operatore: "LigTel Communications", img: "https://www.ligtel.com/images/LigTel-Logo.png", },
  { mcc_mnc: ["312-180", "310-340", "310-690", "311-600"], operatore: "Limitless Mobile  LLC", img: "https://limitlessmobile.com/content/limitless-logo.png", },
  { mcc_mnc: ["310-760"], operatore: "Lynch 3G Communications Corporation", img: "", },
  { mcc_mnc: ["312-960"], operatore: "M&A Technology  Inc.", img: "https://www.macomp.com/wp-content/uploads/thegem-logos/logo_a5e80175885aa73a037789ed1de97f1c_1x.png", },
  { mcc_mnc: ["311-720"], operatore: "MainePCS LLC", img: "", },
  { mcc_mnc: ["313-50"], operatore: "Manti Tele Communications Company  Inc.", img: "https://www.manti.com/wp-content/uploads/MTCC-Logo-2017-W-out_Connecting-White-e1497277684551.png", },
  { mcc_mnc: ["312-980"], operatore: "Mark Twain Communications Company", img: "https://portal.marktwain.net/wp-content/uploads/2017/11/mt-logo-234x74.png", },
  { mcc_mnc: ["310-40", "312-340"], operatore: "Matanuska Telephone Association  Inc.", img: "", },
  { mcc_mnc: ["313-200"], operatore: "Mercury Network Corporation", img: "https://www.mercury.net/img/logo.png", },
  { mcc_mnc: ["311-660"], operatore: "Metro by T-Mobile", img: "https://www.metrobyt-mobile.com/content/dam/digx/m…n/branding/logos/corporate/metro-d-light-2022.svg", },
  { mcc_mnc: ["312-400"], operatore: "Mid-Rivers Telephone Cooperative", img: "https://midrivers.com/wp-content/uploads/2020/04/MR-FullColor.png", },
  { mcc_mnc: ["311-00"], operatore: "Mid-Tex Cellular Ltd.", img: "", },
  { mcc_mnc: ["311-700", "313-70"], operatore: "Midwest Network Solutions Hub LLC", img: "", },
  { mcc_mnc: ["313-550"], operatore: "Mile High Networks LLC", img: "https://static.wixstatic.com/media/8a2422_7cf6dd19…66_1.00_0.01,enc_auto/MileHighNetworks_edited.png", },
  { mcc_mnc: ["311-20", "311-920"], operatore: "Missouri RSA 5 Partnership", img: "", },
  { mcc_mnc: ["313-460"], operatore: "Mobi  Inc.", img: "", },
  { mcc_mnc: ["312-580"], operatore: "Morgan  Lewis & Bockius LLP", img: "https://www.morganlewis.com/-/media/starterkit/ml-logo_white_281x45.png?rev=f51b3f514b9c4a00a26938252dcfd715", },
  { mcc_mnc: ["311-500"], operatore: "Mosaic Telecom", img: "https://experiencemosaic.com/wp-content/uploads/2022/09/Mosaic-Logo-Horizontal-210.png", },
  { mcc_mnc: ["312-330"], operatore: "Nemont Communications  Inc.", img: "", },
  { mcc_mnc: ["310-290"], operatore: "NEP Cellcorp Inc.", img: "", },
  { mcc_mnc: ["312-630"], operatore: "NetGenuity  Inc.", img: "http], //netgenuity.com/wp-content/uploads/2016/08/cropped-NetGenuity-Logo-Small.jpg", },
  { mcc_mnc: ["311-380"], operatore: "New Dimension Wireless Ltd.", img: "", },
  { mcc_mnc: ["310-100"], operatore: "New Mexico RSA 4 East LP", img: "", },
  { mcc_mnc: ["310-600"], operatore: "NewCell Inc.", img: "", },
  { mcc_mnc: ["310-460", "311-530"], operatore: "NewCore Wireless LLC", img: "https://www.newcore.ca/images/cmp_logo_fin.jpg", },
  { mcc_mnc: ["311-100", "312-420"], operatore: "Nex-Tech Wireless", img: "https://www.nex-techwireless.com/assets/images/layout/logo.svg", },
  { mcc_mnc: ["316-10"], operatore: "Nextel Communications", img: "https://en.wikipedia.org/wiki/File], Nextel_logo.svg", },
  { mcc_mnc: ["311-300"], operatore: "Nexus Communications  Inc.", img: "https://nexuscommunications.com/wp-content/uploads/2020/12/Nexus-logo-reverse.svg", },
  { mcc_mnc: ["312-560"], operatore: "NHLT Inc.", img: "", },
  { mcc_mnc: ["313-540"], operatore: "Nokia Innovations US LLC", img: "", },
  { mcc_mnc: ["311-610", "312-230"], operatore: "North Dakota Network Co.", img: "https://www.ndit.nd.gov/sites/www/files/styles/logo/public/documents/theme/Information-Technology-SWP-white-reverse.png?itok=Ymn_Jc-m", },
  { mcc_mnc: ["310-17"], operatore: "North Sight Communications Inc.", img: "https://northsightpr.com/wp-content/uploads/2020/05/NS-left-Color.png", },
  { mcc_mnc: ["311-710", "312-920"], operatore: "Northeast Wireless Networks LLC", img: "https://image.pitchbook.com/7ZL4PB5SBTHkQe2We16bIZlXCHO1621240848777_200x200", },
  { mcc_mnc: ["312-590"], operatore: "Northern Michigan University", img: "", },
  { mcc_mnc: ["311-420", "312-150"], operatore: "Northwest Missouri Cellular LP", img: "https://www.nwmcell.com/themes/custom/rye/images/logo-nwmcell.png", },
  { mcc_mnc: ["310-510", "311-840", "311-850"], operatore: "Nsight", img: "https://www.nsight.com/images/nsight-logo.png", },
  { mcc_mnc: ["310-620"], operatore: "Nsighttel Wireless LLC", img: "https://www.nsighttel.com/wp-content/uploads/2020/04/Header-Nsight-Logo-1.svg", },
  { mcc_mnc: ["312-610", "312-660"], operatore: "nTelos Wireless", img: "https://en.wikipedia.org/wiki/File], NTelos_Logo.svg", },
  { mcc_mnc: ["310-370", "310-470"], operatore: "NTT DoCoMo Pacific", img: "", },
  { mcc_mnc: ["313-40"], operatore: "Nucla-Naturita Telephone Company", img: "https://www.nntc.bz/wp-content/uploads/2020/04/NNTC-logo.jpg", },
  { mcc_mnc: ["310-440", "310-640"], operatore: "Numerex", img: "", },
  { mcc_mnc: ["312-850"], operatore: "Onvoy Spectrum  LLC", img: "", },
  { mcc_mnc: ["313-380"], operatore: "OptimERA Inc.", img: "", },
  { mcc_mnc: ["311-560"], operatore: "OTZ Communications  Inc.", img: "https://www.nwarctic.org/cms/lib/AK01001584/Centri…//logos/Atautchikun%20district%20logo%20small.png", },
  { mcc_mnc: ["310-810"], operatore: "Pacific Lightwave Inc.", img: "https://www.paclw.com/wp-content/uploads/2017/11/logo.png", },
  { mcc_mnc: ["313-320"], operatore: "Paladin Wireless", img: "https://www.paladinwireless.com/files/6614/3628/9559/logo.png", },
  { mcc_mnc: ["311-450"], operatore: "Panhandle Telecommunication Systems Inc.", img: "https://www.ptci.net/wp-content/uploads/2021/03/logo-ptci@2x.png", },
  { mcc_mnc: ["313-410"], operatore: "pdvWireless", img: "https://bearcom.com/static/frontend/Bearcom/ninth/en_US/images/imgpsh_fullsizeanim.png", },
  { mcc_mnc: ["311-670"], operatore: "Pine Belt Cellular Inc.", img: "https://static.wixstatic.com/media/d3cecc_6e03defe…o/d3cecc_6e03defee4ae4476b84ac8b489167bfc~mv2.png", },
  { mcc_mnc: ["311-80"], operatore: "Pine Telephone Company", img: "", },
  { mcc_mnc: ["310-790", "311-290"], operatore: "PinPoint Communications Inc.", img: "https://static.wixstatic.com/media/c42204_dcb00a1f…c,q_85,usm_0.66_1.00_0.01,enc_auto/pnpt_black.png", },
  { mcc_mnc: ["312-990"], operatore: "Premier Holdings LLC", img: "", },
  { mcc_mnc: ["311-540", "311-730"], operatore: "Proximiti Mobility Inc.", img: "http], //www.proximiti.com/images/proximiti_logo_white.jpg", },
  { mcc_mnc: ["310-110"], operatore: "PTI Pacifica Inc.", img: "", },
  { mcc_mnc: ["310-500"], operatore: "Public Service Cellular Inc.", img: "", },
  { mcc_mnc: ["313-510"], operatore: "Puerto Rico Telecom Company", img: "", },
  { mcc_mnc: ["313-490"], operatore: "Puloli  Inc.", img: "https://puloli.com/wp-content/uploads/2020/04/Puloli_logo_2020.svg", },
  { mcc_mnc: ["313-480"], operatore: "Ready Wireless  LLC", img: "https://readywireless.com/wp-content/uploads/2023/01/cropped-ReadyIoT_LOGO_white-100x39.png", },
  { mcc_mnc: ["313-370"], operatore: "Red Truck Wireless  LLC", img: "", },
  { mcc_mnc: ["312-780"], operatore: "Redzone Wireless", img: "https://www.redzonewireless.com/img/redzone_broadband-for-me.svg", },
  { mcc_mnc: ["314-100", "314-190"], operatore: "Reserved for Public Safety", img: "", },
  { mcc_mnc: ["311-430"], operatore: "RSA 1 LP", img: "", },
  { mcc_mnc: ["312-160"], operatore: "RSA1 Limited Partnership", img: "", },
  { mcc_mnc: ["312-320"], operatore: "RTC Communications LLC", img: "https://www.rtccom.com/wp-content/uploads/2022/01/RTC-Logo-V01-Variations-07-copy.png", },
  { mcc_mnc: ["311-350", "312-480", "312-600", "312-640"], operatore: "Sagebrush Cellular  Inc.", img: "", },
  { mcc_mnc: ["313-470"], operatore: "San Diego Gas & Electric Company", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Sdgenewlogo.png/220px-Sdgenewlogo.png", },
  { mcc_mnc: ["312-820"], operatore: "Santel Communications Cooperative  Inc.", img: "", },
  { mcc_mnc: ["313-500"], operatore: "Shelcomm  Inc.", img: "", },
  { mcc_mnc: ["311-910"], operatore: "SI Wireless LLC", img: "https://www.systemsintegrated.com/assets/images/si-logo-new-big-transparent-tight-552x128.png", },
  { mcc_mnc: ["312-430"], operatore: "Silver Star Communications", img: "https://www.silverstar.com/wp-content/uploads/2020/09/SSLogo-no-tagline.svg", },
  { mcc_mnc: ["310-320"], operatore: "Smith Bagley  Inc.", img: "", },
  { mcc_mnc: ["311-820"], operatore: "Sonus Networks", img: "https://ribboncommunications.com/themes/custom/ribbon2020/images/ribbon-logo-color-1.svg", },
  { mcc_mnc: ["312-80", "312-840"], operatore: "South Georgia Regional Information Technology Authority", img: "", },
  { mcc_mnc: ["310-15", "312-720", "316-11"], operatore: "Southern Communications", img: "https://www.southern-comms.co.uk/wp-content/themes/southerncomms-scl/img/logo-nov-22.svg", },
  { mcc_mnc: ["311-60"], operatore: "Space Data Corporation", img: "https://spacedata.net/wp-content/uploads/2020/05/logo.png", },
  { mcc_mnc: ["313-450"], operatore: "Spectrum Wireless Holdings  LLC", img: "", },
  { mcc_mnc: ["313-80"], operatore: "Speedwavz LLP", img: "https://speedwavz.net/wp-content/uploads/2019/05/logo.png", },
  { mcc_mnc: ["310-53", "310-120", "310-830", "311-260", "311-490", "311-870", "311-880", "311-882", "311-940", "312-190", "312-240", "312-250", "312-530"], operatore: "Sprint", img: "https://www.sistemaproprietaintellettuale.it/public/img/Sprint.png", },
  { mcc_mnc: ["311-640"], operatore: "Standing Rock Telecommunications", img: "https://lirp.cdn-website.com/bced3c6c/dms3rep/multi/opt/Standing-Rock-Telecom-222w.png", },
  { mcc_mnc: ["311-360"], operatore: "Stelera Wireless", img: "https://www.americanlegal.com/theme/americanlegalclaims/images/american_legal_button.png", },
  { mcc_mnc: ["310-550", "310-720"], operatore: "Syniverse Technologies", img: "", },
  { mcc_mnc: ["311-930"], operatore: "Syringa Wireless", img: "https://uploads-ssl.webflow.com/61a50738b192e1672c7d9e24/61b4e29e1e0ca88604a97042_Syringa_Networks_logo_white.png", },
  { mcc_mnc: ["310-200", "310-210", "310-220", "310-230", "310-240", "310-250", "310-270", "310-310", "310-490", "310-800", "310-160", "310-260"], operatore: "T-Mobile USA", img: "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg", },
  { mcc_mnc: ["311-170"], operatore: "Tampnet", img: "https://www.tampnet.com/hubfs/Profile/logo-tampnet-white.svg", },
  { mcc_mnc: ["311-740"], operatore: "Telalaska Cellular", img: "https://www.telalaska.com/wp-content/uploads/2022/08/thumbnail_image005.jpg", },
  { mcc_mnc: ["311-690"], operatore: "TeleBEEPER of New Mexico", img: "", },
  { mcc_mnc: ["310-840", "312-300"], operatore: "Telecom North America Mobile  Inc.", img: "https://en.wikipedia.org/wiki/File], 2021.04.30-Telna-Logo-360x360.svg", },
  { mcc_mnc: ["310-140"], operatore: "Teleguam Holdings  LLC", img: "https://en.wikipedia.org/wiki/File], GTA_Teleguam_logo.svg", },
  { mcc_mnc: ["311-210"], operatore: "Telnyx LLC", img: "", },
  { mcc_mnc: ["313-00"], operatore: "Tennessee Wireless", img: "http], //tennesseewireless.com/wp-content/uploads/2013/11/250-x-68-Banner.jpg", },
  { mcc_mnc: ["311-620"], operatore: "TerreStar Networks  Inc.", img: "", },
  { mcc_mnc: ["312-110"], operatore: "Texas Energy Network LLC", img: "", },
  { mcc_mnc: ["310-980"], operatore: "Texas RSA 7B3", img: "https://rsaorg.com/wp-content/uploads/2017/10/RSALogo_SmallDark.png", },
  { mcc_mnc: ["313-400"], operatore: "Texoma Communications  LLC", img: "", },
  { mcc_mnc: ["312-690"], operatore: "TGS  LLC", img: "", },
  { mcc_mnc: ["311-50", "311-830"], operatore: "Thumb Cellular LP", img: "", },
  { mcc_mnc: ["310-520"], operatore: "Transaction Network Services", img: "https://tnsi.com/wp-content/uploads/2022/09/brand.svg", },
  { mcc_mnc: ["313-560"], operatore: "Transit Wireless LLC", img: "https://transitwireless.com/wp-content/uploads/cropped-TW-logo-2@4x.png", },
  { mcc_mnc: ["312-350", "312-730"], operatore: "Triangle Communication System Inc.", img: "https://www.itstriangle.com/images/trianglelogosvg.svg", },
  { mcc_mnc: ["312-490"], operatore: "TrustComm  Inc.", img: "https://files.spazioweb.it/3e/49/3e49cbf5-3f07-44a5-a51e-e03bc9cd4781.png", },
  { mcc_mnc: ["310-860"], operatore: "TX RSA 15B2  LP", img: "", },
  { mcc_mnc: ["310-570"], operatore: "TX-10  LLC and Central Louisiana Cellular  LLC (MTPCS)", img: "", },
  { mcc_mnc: ["310-390"], operatore: "TX-11 Acquisition  LLC", img: "", },
  { mcc_mnc: ["310-940"], operatore: "Tyntec Inc.", img: "https://www.tyntec.com/themes/custom/tyntec/image/tyntec_logo_large.svg", },
  { mcc_mnc: ["310-66", "310-730", "311-220", "311-580"], operatore: "U.S. Cellular", img: "https://it.wikipedia.org/wiki/File], Uscellular_logo20.png", },
  { mcc_mnc: ["310-960"], operatore: "UBET Wireless", img: "", },
  { mcc_mnc: ["311-860", "312-290"], operatore: "Uintah Basin Electronic Telecommunications", img: "", },
  { mcc_mnc: ["310-20"], operatore: "Union Telephone Company", img: "https://uniontel.net/wp-content/uploads/2020/07/Union-Tel-logo.png", },
  { mcc_mnc: ["311-650"], operatore: "United Wireless", img: "https://getunited.com/wp-content/uploads/2022/01/United-Logo.png", },
  { mcc_mnc: ["313-230"], operatore: "Velocity Communications Inc.", img: "https://www.velotech.net/images/logo.png", },
  { mcc_mnc: ["310-4", "310-5", "310-6", "310-10", "310-12", "310-13", "310-350", "310-590", "310-820", "310-890", "310-910", "311-12", "311-110", "311-270", "311-271", "311-272", "311-273", "311-274", "311-275", "311-276", "311-277", "311-278", "311-279", "311-280", "311-281", "311-282", "311-283", "311-284", "311-285", "311-286", "311-287", "311-288", "311-289", "311-390", "311-480", "311-481", "311-482", "311-483", "311-484", "311-485", "311-486", "311-487", "311-488", "311-489", "311-590", "312-770", "310-450", "310-740"], operatore: "Verizon Communications", img: "https://upload.wikimedia.org/wikipedia/commons/8/81/Verizon_2015_logo_-vector.svg", },
  { mcc_mnc: ["311-470"], operatore: "Vitelcom Cellular Inc.", img: "", },
  { mcc_mnc: ["313-90"], operatore: "Vivint Wireless  Inc.", img: "", },
  { mcc_mnc: ["312-200"], operatore: "Voyager Mobility LLC", img: "https://images.squarespace-cdn.com/content/v1/617031493dc8bb165d9600aa/339c2053-c859-4cfc-bfbf-795e0d88878e/VGM%2BNew%2Blogo%2Bgreen%2Bno%2Btext.png?format=1500w", },
  { mcc_mnc: ["311-990"], operatore: "VTel Wireless", img: "https://www.vtelwireless.com/wp-content/uploads/2018/09/vtel-logo.png", },
  { mcc_mnc: ["310-400", "310-480", "311-120", "311-250"], operatore: "Wave Runner LLC", img: "", },
  { mcc_mnc: ["312-940"], operatore: "Webformix", img: "https://www.webformix.com/wp-content/uploads/2021/05/clean-horizonal-logo-small.png", },
  { mcc_mnc: ["312-360"], operatore: "Wes-Tex Telecommunications  Ltd.", img: "https://westex.coop/wp-content/uploads/2020/09/logo-160x62.png", },
  { mcc_mnc: ["310-180"], operatore: "West Central Wireless", img: "https://www.westcentral.com/images/West-Central.png", },
  { mcc_mnc: ["311-150"], operatore: "Wilkes Cellular", img: "", },
  { mcc_mnc: ["310-330", "312-700"], operatore: "Wireless Partners  LLC", img: "https://www.wireless-partnersllc.com/wp-content/themes/wireless_partners_theme/assets/images/wireless-partners-logo.png", },
  { mcc_mnc: ["312-510"], operatore: "WUE Inc.", img: "", },
  { mcc_mnc: ["310-660"], operatore: "", img: "", },
  // australia
  { mcc_mnc: ["50501", "50511", "50539", "50571", "50572"], operatore: "Telstra", img: "https://upload.wikimedia.org/wikipedia/commons/0/0b/Telstra_logo_2023.svg", },
  { mcc_mnc: ["50502", "50590"], operatore: "Optus", img: "https://www.ilcittadinomb.it/wp-content/uploads/2022/09/image-227.png", },
  { mcc_mnc: ["50503", "50506", "50507", "50512"], operatore: "Vodafone", img: "https://www.vodafone.com.au/images/icons/VF-logo-desktop.svg", },
  { mcc_mnc: ["50519"], operatore: "Lycamobile", img: "https://cms-assets.ldsvcplatform.com/IRE/s3fs-public/2023-09/home_logo.png" },
  // colombia
  { mcc_mnc: ["732-101"], operatore: "Claro", img: "https://www.claro.com.co/portal/co/recursos_contenido/1704216919651-1661457416868-claro-musica.png" },
  { mcc_mnc: ["732-123"], operatore: "Movistar", img: "https://www.movistar.com.co/assets/img/brand-movistar.svg" },
  { mcc_mnc: ["732-103", "732-165"], operatore: "Tigo", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Tigolatam.png/800px-Tigolatam.png" },
  { mcc_mnc: ["732-360"], operatore: "Wom", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/WOM_logo.png/640px-WOM_logo.png" },
  { mcc_mnc: ["732-154"], operatore: "Virgi Mobile", img: "https://cdn1.virginmobile.co/wp-content/uploads/wpfiles/20220215212620/logo-vmco-1.svg" },
  { mcc_mnc: ["732-187"], operatore: "ETB", img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/ETB_Bogot%C3%A1_logo.svg/440px-ETB_Bogot%C3%A1_logo.svg.png" },
];

function setCellCodeFormat0(lac: string, cellId: string, tec: string): any {
  if (tec.indexOf("U") >= 0 || tec.indexOf("G") >= 0) {
    if (lac.length < 5) {
      while (lac.length != 5) {
        lac = "0" + lac;
      }
    }
    if (cellId.length < 5) {
      while (cellId.length != 5) {
        cellId = "0" + cellId;
      }
    }
  } else if (tec.indexOf("L") >= 0) {
    if (lac.length < 7) {
      while (lac.length != 7) {
        lac = "0" + lac;
      }
    }
    if (cellId.length < 3) {
      while (cellId.length != 3) {
        cellId = "0" + cellId;
      }
    }
  } else if (tec.indexOf("5GNSA")) {
    lac = "0000000";
    cellId = "000";
  }
  const lac_cellid = lac + "-" + cellId;
  return lac_cellid;
}

export interface IScanResult {
  ScanNumber: number;
}

export class OperatoriTel {
  name = "";
  img = "";
  constructor(name: string, img: string) {
    this.name = name;
    this.img = img;
  }
}

export class frequenze {
  download = 0;
  constructor(download: number) {
    this.download = download;
  }
}

interface AvailableCell {
  cellCode: string;
  band: string;
  arfcn: number;
  power: number;
  operatore: string;
  img: string;
  down_frequency: number;
}

export class ScanResultH implements IScanResult {
  location: number[] = [];
  ScanNumber = 0;
  Technology = "";
  LastPart = 0;
  time: Date | undefined;
  Cells: AvailableCell[] = [];

  constructor(ScanNumber: number, Technology: string, Part: number, location: number[]) {
    this.ScanNumber = ScanNumber;
    if (Technology.indexOf("L") >= 0) {
      this.Technology = "LTE";
    } else if (Technology.indexOf("U") >= 0) {
      this.Technology = "UMTS";
    } else if (Technology.indexOf("G") >= 0) {
      this.Technology = "GSM";
    }
    this.LastPart = Part;
    this.location = location;
  }

  addCells(cell: string, band: string, freq: number, power: number, operator: OperatoriTel, down: number) {
    this.Cells.push(
      {
        cellCode: cell,
        band: band,
        arfcn: freq,
        power: power,
        operatore: operator.name,
        img: operator.img,
        down_frequency: (Number)(down.toFixed(1)),
      }
    );
  }

  getString() {
    let s = "";
    let i = 0;
    for (i = 0; i < this.Cells.length; i++) {
      s = s + this.Cells[i] + ";";
    }
    return s;
  }
}

export class ScanResultS implements IScanResult {
  ScanNumber = 0;
  Technology = "";
  operatore: OperatoriTel;
  constructor(ScanNumber: number, Technology: string, operatore: OperatoriTel) {
    this.ScanNumber = ScanNumber;
    this.Technology = Technology;
    this.operatore = operatore;
  }
}

export class ScanResultS_5GNSA extends ScanResultS {
  ScellID = "";
  frequencyBand = "";
  rsrp = 0;
  lte: ScanResultS_lte;
  constructor(ScanNumber: number, Technology: string,
    ScellID: string,
    frequencyBand: string,
    rsrq: string,
    lte: ScanResultS_lte
  ) {
    super(ScanNumber, Technology, lte.operatore);
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrq) / 10;
    this.lte = lte;
  }
}

export class ScanResultS_5GSA extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  frequencyBand = "";
  rsrp = 0;
  cellcode = "";
  frequency: frequenze;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    ScellID: string,
    frequencyBand: string,
    rsrq: string,
    operatore: OperatoriTel,
    frequency: frequenze
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrq) / 10;
    this.frequency = frequency;
  }
  setCellCode() {
    const value = parseInt(this.ScellID, 16);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    const lac_cellid = setCellCodeFormat0(String(value1), String(value2), "5GSA");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
}

export class ScanResultS_wcdma extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  tac = "";
  frequencyBand = "";
  cellcode = "";
  value = 0; value1 = 0; value2 = 0;
  cellcodeS = "";
  frequency = "";
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    tac: string,
    ScellID: string,
    frequencyBand: string,
    operatore: OperatoriTel,
    frequency: string
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.tac = tac;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.frequency = frequency;
  }
  setCellCode() {
    const lac = parseInt(this.tac);
    const scellid = parseInt(this.ScellID);
    const rncid = scellid / 65536;
    const cid = scellid % 65536;
    const lac_cellid = setCellCodeFormat0(String(lac), String(cid), "U");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
}
export class ScanResultS_lte extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  tac = "";
  frequencyBand = "";
  rsrp = 0;
  cellcode = "";
  value = 0; value1 = 0; value2 = 0;
  cellcodeS = "";
  frequency: frequenze;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    tac: string,
    ScellID: string,
    frequencyBand: string,
    rsrp: string,
    operatore: OperatoriTel,
    frequency: frequenze
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.tac = tac;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrp) / 10;
    this.frequency = frequency;
  }
  setCellCode() {
    const value = Number(this.ScellID);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    const lac_cellid = setCellCodeFormat0(String(value1), String(value2), "L");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
  setCellCodeSecond() {
    if (this.mcc_mnc.indexOf("222-88") >= 0 || this.mcc_mnc.indexOf("222-99") >= 0 || this.mcc_mnc.indexOf("222-50") >= 0) {
      const num = parseInt(this.tac, 16);
      const lac_cellid = setCellCodeFormat0(String(num), String(this.ScellID), "L");
      this.cellcodeS = " | " + this.mcc_mnc + "-" + lac_cellid;
    }
  }
}

export class ScanResultS_noService extends ScanResultS {
  OperationMode = "";
  constructor(ScanNumber: number, Technology: string, OperationMode: string,
    operatore: OperatoriTel
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
  }
}

export class ActiveCell extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  lac = "";
  cellId = "";
  arfcn = "";
  signal = "";
  cellcode = "";
  type = "";
  band = "";
  constructor(ScanNumber: number, Technology: string,
    operationMode: string, mcc_mnc: string, lac: string, cellId: string,
    arfcn: string, signal: string, operatore: OperatoriTel, band: string
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = operationMode;
    this.mcc_mnc = mcc_mnc;
    this.lac = lac.length == 0 ? "0" : lac;
    this.cellId = cellId.length == 0 ? "0" : cellId;
    this.arfcn = arfcn;
    this.signal = signal;
    this.setBand(band);
  }
  setBand(band: string) {
    switch (band) {
      case "0": {
        this.band = "GSM 850";
        return;
      }
      case "1": {
        this.band = "GSM 900";
        return;
      }
      case "2": {
        this.band = "GSM 1800";
        return;
      }
      case "3": {
        this.band = "GSM 1900";
        return;
      }
      default:
        this.band = band;
        return;
    }
  }
  setCellCode() {
    const lac = parseInt(this.lac, 16);
    const cellid = parseInt(this.cellId, 16);
    const lac_cellid = setCellCodeFormat0(String(lac), String(cellid), "G");
    this.cellcode = this.mcc_mnc + "-" + lac_cellid;
  }
  setType(type: string) {
    this.type = type;
  }
}

export class ActiveScan implements IScanResult {
  ScanNumber = 0;
  Cell: ActiveCell[] = [];

  constructor(ScanNumber: number) {
    this.ScanNumber = ScanNumber;
  }
  addCell(cell: ActiveCell) {
    this.Cell.push(cell);
  }
}

export class ScanGPS implements IScanResult {
  ScanNumber = 0;
  latitude = 0
  longitude = 0;
  altitude = "";
  constructor(ScanNumber: number, Altitude: string) {
    this.ScanNumber = ScanNumber;
    this.altitude = Altitude;
  }
  setConversion(value: string, ind: string, type: number) {
    let deg = "", min = "";
    let m1 = -1, m2 = -1;
    let i = 0;
    for (i = 0; i < value.length; i++) {
      if (value.charAt(i) == '.') {
        m1 = i - 2;
        m2 = i - 1;
        min = min + value.charAt(m1) + value.charAt(m2) + value.charAt(i);
      }
      else if (m1 != -1) {
        min = min + value.charAt(i);
      }
    }
    for (i = 0; i < m1; i++) {
      deg = deg + value.charAt(i);
    }
    const degrees = Number(deg);
    const minutes = Number(min);
    let dec = degrees + (minutes / 60);
    if (ind.indexOf("S") >= 0 || ind.indexOf("W") >= 0) {
      dec = -1 * dec;
    }
    if (type == 1) {
      this.latitude = dec;
    } else {
      this.longitude = dec;
    }
  }
}

export class ScanResult {
  static parseFromAtCommand(atComm: string, surveyNum: number, location: number[]): IScanResult | null | undefined { // per moduli B,C,D
    if (atComm == null || atComm.length < 5) {
      return null;
    }
    if (atComm.indexOf("^NETSCAN") >= 0) {
      return ScanResult.parseHuaAtCommand(atComm, surveyNum, location);
    }
    else if (atComm.indexOf("+CPSI") >= 0) {
      return ScanResult.parseSimAtCommand(atComm, surveyNum, location);
    }
    else if (atComm.indexOf("+CGPSINFO") >= 0) {
      return ScanResult.parseGPSCommand(atComm, surveyNum, "");
    }
    return null;
  }

  static parseAtCommandHauwei(atComm: string, surveyNum: number, part: number, type: string, location: number[]) { // per moduli 1,2,3
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    const activeScan = new ActiveScan(surveyNum);
    const commands = atComm.split("AT^MONNC");
    let atMonsc = atComm, atMonnc = atComm;
    if (commands.length == 2) {
      atMonsc = commands[0];
      atMonnc = commands[1];
    } else if (commands.length == 1) {
      if (commands[0].indexOf("^MONSC") >= 0) {
        atMonsc = commands[0];
      } else {
        atMonnc = commands[0];
      }
    }
    if (atMonsc.indexOf("^MONSC") >= 0) {
      atMonsc = atMonsc.replace("AT^MONSC^MONSC: ", "");
      if (atMonsc.length == 0) return;
      const monsc = atMonsc.split(",");
      const servingCell = new ActiveCell(surveyNum, monsc[0], "", monsc[1] + "-" + monsc[2], monsc[7], monsc[6], monsc[4], monsc[8], this.getOperatore(monsc[1] + "-" + monsc[2]), (monsc[3]));
      servingCell.setCellCode();
      servingCell.setType("Serving");
      activeScan.addCell(servingCell);
      $BTSTracker.value.mcc_mncServingCell = monsc[1] + "-" + monsc[2];
    }
    if (atMonnc.indexOf("^MONNC") >= 0) {
      atMonnc = atMonnc.replaceAll("^MONNC: ", ";");
      if (atMonnc.length == 0) return;
      const cell = atMonnc.split(";");
      for (let i = 0; i < cell.length; i++) {
        if (cell[i].length > 0) {
          const gsm = cell[i].split(",");
          if (gsm[0].indexOf("GSM") >= 0) {
            const op = new OperatoriTel("", "");
            if (gsm[0].indexOf("GSM") >= 0) {
              const cellGSM = new ActiveCell(surveyNum, gsm[0], "", $BTSTracker.value.mcc_mncServingCell, gsm[5], gsm[4], gsm[2], gsm[6], op, (gsm[1]));
              cellGSM.setCellCode();
              cellGSM.setType("Neighbor");
              activeScan.addCell(cellGSM);
            } else {
              // NONE
              break;
            }
          }
        }
      }
      return activeScan;
    }
    const cell = new ScanResultH(surveyNum, type, part, location);
    atComm = atComm.replaceAll("^NETSCAN: ", ";");
    const net = atComm.split(";");
    let i = 0;
    for (i = 1; i < net.length; i++) {
      const info = net[i].split(",");
      if (info[4].length > 0 && info[5].length > 0 && info[3].length > 0 && info[8].length > 0) {
        let v1, v2;
        if (type.indexOf("L") >= 0) {
          v1 = Math.floor(parseInt(info[8], 16) / 256);
          v2 = (Number)(parseInt(info[8], 16) % 256);
        } else if (type.indexOf("G") >= 0) {
          v1 = parseInt(info[3], 16);
          v2 = parseInt(info[8], 16);
        } else if (type.indexOf("U") >= 0) {
          //console.log(info);
          const lac = parseInt(info[3], 16); //11001
          const len = (info[8] + "").length; // "1D0B3BC"
          let cid = 0;
          if (len > 2) {
            cid = parseInt(info[8].substring(len - 4), 16); //46012
          }
          v1 = lac;
          v2 = cid;
        }
        const c1 = ScanResult.cellH(info[4], info[5], (String)(v1), (String)(v2), type);
        let band;
        if (type.indexOf("L") >= 0) {
          band = this.getBand(1, type, (String)(info[0]));
        } else {
          band = this.getBand(1, type, info[9]);
        }
        const mcc_mnc = info[4] + "-" + info[5];
        const operator = this.getOperatore(mcc_mnc);
        const frequency = this.getFrequency(type, (Number)(info[0]));
        cell.addCells(c1, band, (Number)(info[0]), (Number)(info[7]), operator, frequency.download);
      }
    }
    return cell;
  }

  static parse4GModule(atComm: string, surveyNum: number, part: number, type: string, location: number[]): IScanResult | null | undefined {
    if (atComm.indexOf("+CGPSINFO") >= 0) {
      return ScanResult.parseGPSCommand(atComm, surveyNum, type);
    } else if (type.indexOf("L") >= 0 || type.indexOf("U") >= 0 || type.indexOf("G") >= 0) {
      return ScanResult.parseAtCommandH2(atComm, surveyNum, part, type, location);
    } else {
      return ScanResult.parseSimAtCommand(atComm, surveyNum, location);
    }
  }

  static parseAtCommandH2(atComm: string, surveyNum: number, part: number, type: string, location: number[]) { // per moduli 4,5
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    if (atComm.indexOf("END") >= 0) {
      return null;
    }
    if (atComm.indexOf("no results") >= 0) {
      return null;
    }
    const cell = new ScanResultH(surveyNum, type, part, location);
    let resp = atComm.replaceAll('"', "");
    resp = resp.replaceAll("\r", "");
    if (resp.length > 0) {
      let command: any;
      if (resp.indexOf("+AOPS") >= 0) {
        resp = resp.replace("+", ";+");
      }
      if (resp.indexOf(";") >= 0) {
        command = resp.split(";");
      }
      for (let x = 0; x < command.length; x++) {
        if (command[x].length > 0) {
          if (command[x].indexOf("+AOPS:") >= 0) {
            command[x] = command[x].replace("+AOPS:", "");
            $BTSTracker.value.aops = command[x].split(",");
          } else {
            const cellAvailable = command[x].split(",");
            for (let i = 0; i < cellAvailable.length; i++) {
              if (cellAvailable[i].indexOf(":") >= 0) {
                const value = cellAvailable[i].split(":");
                cellAvailable[i] = value[1];
              }
            }
            const mcc = $BTSTracker.value.aops[2].charAt(0) + $BTSTracker.value.aops[2].charAt(1) + $BTSTracker.value.aops[2].charAt(2);
            let mnc = "";
            for (let y = 0; y < $BTSTracker.value.aops[2].length; y++) {
              if (y > 2) {
                mnc = mnc + $BTSTracker.value.aops[2].charAt(y);
              }
            }
            if (mnc.endsWith(' ')) {
              mnc = mnc.slice(0, -1);
            }
            let v1, v2;
            if (cellAvailable.length == 9) {
              if (type.indexOf("U") >= 0) {
                v1 = cellAvailable[6];
                v2 = (Number)(parseInt(cellAvailable[7]) % 65536);
                //console.log(v1);
                //console.log(v2);
              } else if (type.indexOf("L") >= 0) {
                v1 = Math.floor(parseInt(cellAvailable[7]) / 256);
                v2 = (Number)(parseInt(cellAvailable[7]) % 256);
              }
            } else if (cellAvailable.length == 10) {
              if (type.indexOf("G") >= 0) { // gsm
                v1 = cellAvailable[5];
                v2 = cellAvailable[8];
              }
            } else {
              return null;
            }
            const c = ScanResult.cellH(mcc, mnc, (String)(v1), (String)(v2), type);
            for (let x = 0; x < c.length; x++) {
              if (c.charAt(x) < '0' || c.charAt(x) > '9') {
                if (c.charAt(x) != '-') {
                  return null;
                }
              }
            }
            const band = this.getBand(3, type, (String)(cellAvailable[2]));
            const mcc_mnc = mcc + "-" + mnc;
            const operator = this.getOperatore(mcc_mnc);
            const frequency = this.getFrequency(type, (Number)(cellAvailable[2]));
            cell.addCells(c, band, (Number)(cellAvailable[2]), (Number)(cellAvailable[3]), operator, frequency.download);
          }
        }

      }
      // se è l'intestazione per cambio operatore
    }
    return cell;
  }

  static getFrequency(type: string, arfcn: number): frequenze {
    let download = 0;
    if (type.indexOf("G") >= 0) {
      if (arfcn >= 259 && arfcn <= 293) {
        download = 450.6 + 0.2 * (arfcn - 259);
      } else if (arfcn >= 306 && arfcn <= 340) {
        download = 479 + 0.2 * (arfcn - 306);
      } else if (arfcn >= 438 && arfcn <= 511) {
        download = 747.2 + 0.2 * (arfcn - 438);
      } else if (arfcn >= 128 && arfcn <= 251) {
        download = 824.2 + 0.2 * (arfcn - 128);
      } else if (arfcn >= 1 && arfcn <= 124) {
        download = 890 + 0.2 * arfcn;
      } else if (arfcn >= 975 && arfcn <= 1023) {
        download = 890 + 0.2 * (arfcn - 1024);
      } else if (arfcn >= 512 && arfcn <= 885) {
        download = 1718 + 0.2 * (arfcn - 512);
      }
    }
    if (type.indexOf("L") >= 0) {
      const myBand = lteBands.find(o => o.dl_earfcn && arfcn >= o.dl_earfcn[0] && arfcn <= o.dl_earfcn[1]);
      if (myBand) {
        download = myBand.dl_fr[0] + 0.1 * (arfcn - myBand.dl_earfcn[0]);
      }
      else {
        download = 0;
      }
    }
    let FDL_offset, FUL_offset, diff, Nul;
    if (type.indexOf("U") >= 0) {
      if (((arfcn >= 10562) && (arfcn <= 10838))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 10562;
        Nul = 9612 + diff;
      }
      else if (((arfcn >= 9662) && (arfcn <= 9938))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 9662;
        Nul = 9262 + diff;
      }
      else if (((arfcn >= 1162) && (arfcn <= 1513))) {
        FDL_offset = 1575;
        FUL_offset = 1525;
        diff = arfcn - 1162;
        Nul = 937 + diff;
      }
      else if (((arfcn >= 1537) && (arfcn <= 1738))) {
        FDL_offset = 1805;
        FUL_offset = 1450;
        diff = arfcn - 1537;
        Nul = 1312 + diff;
      }
      else if (((arfcn >= 4357) && (arfcn <= 4458))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 4357;
        Nul = 4132 + diff;
      }
      else if (((arfcn >= 4387) && (arfcn <= 4413))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 4387;
        Nul = 4162 + diff;
      }
      else if (((arfcn >= 2237) && (arfcn <= 2563))) {
        FDL_offset = 2175;
        FUL_offset = 2100;
        diff = arfcn - 2237;
        Nul = 2012 + diff;
      }
      else if (((arfcn >= 2937) && (arfcn <= 3088))) {
        FDL_offset = 340;
        FUL_offset = 340;
        diff = arfcn - 2937;
        Nul = 2712 + diff;
      }
      else if (((arfcn >= 9237) && (arfcn <= 9387))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 9237;
        Nul = 8762 + diff;
      }
      else if (((arfcn >= 3112) && (arfcn <= 3388))) {
        FDL_offset = 1490;
        FUL_offset = 1135;
        diff = arfcn - 3112;
        Nul = 2887 + diff;
      }
      else if (((arfcn >= 3712) && (arfcn <= 3787))) {
        FDL_offset = 736;
        FUL_offset = 733;
        diff = arfcn - 3712;
        Nul = 3487 + diff;
      }
      else if (((arfcn >= 3842) && (arfcn <= 3903))) {
        FDL_offset = -37;
        FUL_offset = -22;
        diff = arfcn - 3842;
        Nul = 3617 + diff;
      }
      else if (((arfcn >= 4017) && (arfcn <= 4043))) {
        FDL_offset = -55;
        FUL_offset = 21;
        diff = arfcn - 4017;
        Nul = 3792 + diff;
      }
      else if (((arfcn >= 4117) && (arfcn <= 4143))) {
        FDL_offset = -63;
        FUL_offset = 12;
        diff = arfcn - 4117;
        Nul = 3892 + diff;
      }
      else if (((arfcn >= 712) && (arfcn <= 763))) {
        FDL_offset = 735;
        FUL_offset = 770;
        diff = arfcn - 712;
        Nul = 312 + diff;
      }
      else if (((arfcn >= 4512) && (arfcn <= 4638))) {
        FDL_offset = -109;
        FUL_offset = -23;
        diff = arfcn - 4512;
        Nul = 4287 + diff;
      }
      else if (((arfcn >= 862) && (arfcn <= 912))) {
        FDL_offset = 1326;
        FUL_offset = 1358;
        diff = arfcn - 862;
        Nul = 462 + diff;
      }
      else if (((arfcn >= 4662) && (arfcn <= 5038))) {
        FDL_offset = 2580;
        FUL_offset = 2525;
        diff = arfcn - 4662;
        Nul = 4437 + diff;
      }
      else if (((arfcn >= 5112) && (arfcn <= 5413))) {
        FDL_offset = 910;
        FUL_offset = 875;
        diff = arfcn - 5112;
        Nul = 4887 + diff;
      }
      else if (((arfcn >= 5762) && (arfcn <= 5913))) {
        FDL_offset = -291;
        FUL_offset = -291;
        diff = arfcn - 5762;
        Nul = 5537 + diff;
      }
      else {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = 0;
        Nul = 0;
      }
      download = (FDL_offset + (0.2 * arfcn));
    }
    if (type.indexOf("5G") >= 0) {
      if (arfcn >= 0 && arfcn <= 599999) {
        download = 0 + 5 * (arfcn - 0);
      } else if (arfcn >= 600000 && arfcn <= 2016666) {
        download = 3000 + 15 * (arfcn - 600000);
      } else if (arfcn >= 2016667 && arfcn <= 3279165) {
        download = 24250.08 + 60 * (arfcn - 20166670);
      }
    }
    return new frequenze(download);
  }

  static getBand(modem: number, type: string, band: string): string {
    let s = "";
    if (modem == 3) {
      const arfcn = (Number)(band);
      if (type.indexOf("G") >= 0) {
        const myBand = gsmBands.find(o => arfcn >= o.earfcn[0] && arfcn <= o.earfcn[1]);
        if (myBand) {
          s = myBand.band;
        } else {
          s = "";
        }
        return s;
      } else if (type.indexOf("U") >= 0) {
        const myBand = umtsBands.find(o => arfcn >= o.earfcn[0] && arfcn <= o.earfcn[1]);
        if (myBand) {
          s = myBand.band;
        } else {
          s = "";
        }
        return s;
      }

    }

    if (band.indexOf("7FFFFFFFFFFFFFFF") >= 0) {
      return "All bands";
    }
    const band_ = (Number)(band);
    if (type.indexOf("L") >= 0) {
      const arfcn = (Number)(band);
      const myBand = lteBands.find(o => o.dl_earfcn && arfcn >= o.dl_earfcn[0] && arfcn <= o.dl_earfcn[1]);
      if (myBand) {
        s = "B" + myBand.band + " " + myBand.duplex;
      }
      else {
        s = "";
      }
      return s;
    } else {
      switch (band_) {
        case 80000:
          s = "GSM 850";
          break;
        case 80:
          s = "GSM DCS systems";
          break;
        case 100:
          s = "Extended GSM 900";
          break;
        case 200:
          s = "Primary GSM 900";
          break;
        case 100000:
        case 100300:
          s = "Railway GSM 900";
          break;
        case 200000:
          s = "GSM PCS";
          break;
        case 400000:
          s = "WCDMA IMT 2100";
          break;
        case 800000:
          s = "WCDMA_II_PCS_1900";
          break;
        case 4000000:
          s = "WCDMA_V_850";
          break;
        case 8000000:
          s = "WCDMA_VI_800";
          break;
        case 4000000000000:
          s = "WCDMA_IX_1700";
          break;
        case 2000000000000:
          s = "WCDMA_VIII_900";
          break;
        case 1000000000000000:
          s = "WCDMA_XIX_850";
          break;
        case 2000000:
          s = "AWS";
          break;
        case 680380:
          s = "Automatic";
          break;
        default:
          s = "" + band;
          break;
      }
      return s;
    }
  }

  static getOperatore(mcc_mnc: string): OperatoriTel {
    const operatorInfo = apn.find(single_apn => single_apn.mcc_mnc.indexOf(mcc_mnc) >= 0);
    if (operatorInfo != null) {
      return new OperatoriTel(operatorInfo.operatore, operatorInfo.img);
    } else {
      return new OperatoriTel("", "");
    }
  }

  private static cellH(mcc: string, mnc: string, val1: string, val2: string, type: string): string {
    const lac_cellid = setCellCodeFormat0(String(val1), String(val2), type);
    return (String)(mcc + "-" + mnc + "-" + lac_cellid);
  }

  private static parseHuaAtCommand(atComm: string, scannum: number, location: number[]): IScanResult | null | undefined {
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    const cell = new ScanResultH(scannum, "", 0, location);
    return cell;
  }

  private static parseSimAtCommand(atComm: string, scannum: number, location: number[]): IScanResult | null | undefined {
    let gsmInfo = "";
    const activeScan = new ActiveScan(scannum);
    if (atComm.indexOf("+GSMCIINFO:") >= 0 && atComm.indexOf("+CPSI") >= 0) {
      atComm = atComm.replace("+GSMCIINFO:", ";+GSMCIINFO:");
      const activeInfo = atComm.split(";");
      atComm = activeInfo[0];
      gsmInfo = activeInfo[2];
    } else if (atComm.indexOf("+GSMCIINFO") >= 0) {
      gsmInfo = atComm;
      atComm = "";
    }
    if (atComm.indexOf("+CPSI") >= 0) {
      const a = atComm.replaceAll("+CPSI: ", ";");
      const arr = a.split(";");
      if (arr.length > 0) {
        if (arr.length == 3) {
          const lte = arr[1].split(",");
          const operator = this.getOperatore(lte[2]);
          const frequency = this.getFrequency(lte[0], (Number)(lte[7]));
          const cellLTE = new ScanResultS_lte(scannum, lte[0], lte[1], lte[2], lte[3], lte[4], lte[6], lte[11], operator, frequency);
          cellLTE.setCellCode();
          cellLTE.setCellCodeSecond();
          const nsa = arr[2].split(",");
          const cell5G = new ScanResultS_5GNSA(scannum, nsa[0], nsa[1], nsa[2], nsa[4], cellLTE);
          return cell5G;
        }
        else if (arr.length == 2) {
          if (arr[1].indexOf("LTE") >= 0) {
            const lte = arr[1].split(",");
            const operator = this.getOperatore(lte[2]);
            const frequency = this.getFrequency(lte[0], (Number)(lte[7]));
            const cellLTE = new ScanResultS_lte(scannum, lte[0], lte[1], lte[2], lte[3], lte[4], lte[6], lte[11], operator, frequency);
            cellLTE.setCellCode();
            cellLTE.setCellCodeSecond();
            return cellLTE;
          } else if (arr[1].indexOf("WCDMA") >= 0) {
            const wcdma = arr[1].split(",");
            const operator = this.getOperatore(wcdma[2]);
            const cellwcdma = new ScanResultS_wcdma(scannum, wcdma[0], wcdma[1], wcdma[2], wcdma[3], wcdma[4], wcdma[5], operator, wcdma[7]);
            cellwcdma.setCellCode();
            return cellwcdma;
          } else if (arr[1].indexOf("NR5G_SA") >= 0) {
            const sa = arr[1].split(",");
            const operator = this.getOperatore(sa[2]);
            const frequency = this.getFrequency(sa[0], (Number)(sa[7]));
            const cellSA = new ScanResultS_5GSA(scannum, sa[0], sa[1], sa[2], sa[4], sa[6], sa[10], operator, frequency);
            cellSA.setCellCode();
            return cellSA;
          } else if (arr[1].indexOf("NO") >= 0) {
            const no = arr[1].split(",");
            const operator = this.getOperatore("");
            const cellNo = new ScanResultS_noService(scannum, no[0], no[1], operator);
            return cellNo;
          } else if (arr[1].indexOf("GSM") >= 0) {
            const gsm = arr[1].split(",");
            const operator = this.getOperatore(gsm[2]);
            const arfcn = gsm[5].split(" ");
            gsm[6] = gsm[6].replaceAll(" ", "");
            const cellGSM = new ActiveCell(scannum, gsm[0], gsm[1], gsm[2], gsm[3], gsm[4], arfcn[0], "-" + gsm[6], operator, (arfcn[1] + " " + arfcn[2]));
            cellGSM.setCellCode();
            cellGSM.setType("Serving");
            $BTSTracker.value.mcc_mncServingCell = gsm[2];
            activeScan.addCell(cellGSM);
          }
        }
      }
    }
    if (gsmInfo.indexOf("+GSMCIINFO:") >= 0) {
      const neighbor = gsmInfo.split("+GSMCIINFO: ");
      for (let i = 0; i < neighbor.length; i++) {
        if (neighbor[i].length > 0) {
          neighbor[i] = neighbor[i].replace(";", "");
          const infos = neighbor[i].split(",");
          for (let x = 0; x < infos.length; x++) {
            if (infos[x].indexOf(":") >= 0) {
              const support = infos[x].split(":");
              infos[x] = support[1];
            }
          }
          if (infos[1].indexOf("000-00") < 0) {
            const signal = -parseInt(infos[4]);
            const neighborCell = new ActiveCell(scannum, "GSM", "", $BTSTracker.value.mcc_mncServingCell, infos[2], infos[3], infos[5], (String)(signal), this.getOperatore($BTSTracker.value.mcc_mncServingCell), "");
            neighborCell.setCellCode();
            neighborCell.setType("Neighbor");
            activeScan.addCell(neighborCell);
          }
        }
      }
    }
    return activeScan;
  }

  private static parseGPSCommand(atComm: string, scannum: number, type: string): IScanResult | null | undefined {
    atComm = atComm.replace("+CGPSINFO: ", "");
    if (atComm.indexOf(",,,,,") >= 0 || atComm.indexOf("error") >= 0) {
      if (type.startsWith("G"))
        $BTSTracker.value.gpsBTS4G = 0;
      else
        $BTSTracker.value.gpsBTS5G = 0;

      return null;
    } else {
      $BTSTracker.value.tipo = 1;
      if (type.startsWith("G"))
        $BTSTracker.value.gpsBTS4G = 1;
      else
        $BTSTracker.value.gpsBTS5G = 1;

      if ($BTSTracker.value.gpsBTS4G == 1 && $BTSTracker.value.gpsBTS5G == 1) {
        if (type.startsWith("G"))
          return null;
      }
      const arr = atComm.split(",");
      const gps = new ScanGPS(scannum, arr[6]);
      gps.setConversion(arr[0], arr[1], 1);
      gps.setConversion(arr[2], arr[3], 2);
      return gps;
    }

  }

}

export class CellRecord {
  id? = undefined;

  date = "";

  // gelocation
  latitude = 0;
  longitude = 0;
  altitude = 0;

  accuracy = 0;
  speed = 0;
  course = 0;
  satellites_in_view = 0;

  // tower identification
  mcc = ""; // cgi
  mnc = "";
  lac = 0;
  cid = 0; //
  c1 = 0;
  c2 = 0;
  arfcn = 0;
  band = "";
  ta = 0;
  eci = 0;
  cell_survey_id = 0;
  is_genuine = false;
  bsic = 0;
  psc = 0;
  pci = 0;
  tac = 0;
  e_node_b = 0;
  power = 0;
  cell_mode = 0;
  tech = 0;
  fileName = "";

  toJson(): string {
    return JSON.stringify(this);
  }

  GetCGI(): string {
    return `${this.mcc}-${this.mnc}-${this.lac}-${this.cid}`;
  }

  GetTech(): string {
    switch (this.tech) {
      case 0:
        return "GSM";
      case 1:
        return "UMTS";
      case 2:
        return "UNKNOWN_2";
      case 3:
        return "LTE";
      case 4:
        return "AutoConnection5G";
      default:
        return "UNKNOWN";
    }
  }

  static fromJson(json: string): CellRecord {
    try {
      const j = JSON.parse(json);
      return Object.assign(new CellRecord(), j);
    } catch (error) { // just for logging the gson
      console.warn("Error parsing json: " + json);
      throw error;
    }
  }
  static fromCsv(csvString: string): CellRecord {
    const csv = csvString.split(";");
    const cell = new CellRecord();
    cell.mcc = csv[0];
    cell.mnc = csv[1];
    cell.lac = parseIntOrZero(csv[2]);
    cell.cid = parseIntOrZero(csv[3]);
    cell.c1 = parseIntOrZero(csv[4]);
    cell.c2 = parseIntOrZero(csv[5]);
    cell.arfcn = parseIntOrZero(csv[6]);
    cell.band = csv[7];
    cell.ta = parseIntOrZero(csv[8]);
    cell.eci = parseIntOrZero(csv[9]);
    cell.cell_survey_id = parseIntOrZero(csv[10]);
    cell.is_genuine = csv[11] === "true";
    cell.bsic = parseIntOrZero(csv[12]);
    cell.psc = parseIntOrZero(csv[13]);
    cell.pci = parseIntOrZero(csv[14]);
    cell.tac = parseIntOrZero(csv[15]);
    cell.e_node_b = parseIntOrZero(csv[16]);
    cell.power = parseIntOrZero(csv[17]);
    cell.cell_mode = parseIntOrZero(csv[18]);
    cell.tech = parseIntOrZero(csv[19]);
    cell.latitude = parseFloatOrZero(csv[20]) / 10000000;
    cell.longitude = parseFloatOrZero(csv[21]) / 10000000;
    cell.altitude = parseFloatOrZero(csv[22]) / 10000000;
    cell.speed = parseFloatOrZero(csv[23]);
    cell.course = parseFloatOrZero(csv[24]);
    cell.satellites_in_view = parseIntOrZero(csv[25]);
    cell.date = csv[26];
    return cell;
  }
  static toCsv(cr: CellRecord): string {
    return `${cr.mcc};${cr.mnc};${cr.lac};${cr.cid};${cr.c1};${cr.c2};${cr.arfcn};${cr.band};${cr.ta};${cr.eci};${cr.cell_survey_id};${cr.is_genuine};${cr.bsic};${cr.psc};${cr.pci};${cr.tac};${cr.e_node_b};${cr.power};${cr.cell_mode};${cr.tech};${cr.latitude};${cr.longitude};${cr.altitude};${cr.speed};${cr.course};${cr.satellites_in_view};${cr.date}`;
  }

  static toJson(cr: CellRecord): string {
    return JSON.stringify(cr);
  }
  _toJson(): string {
    return JSON.stringify(this);
  }
}

interface scanWifi {
  name: string;
  signal: number;
  encryp: string;
  mac: string;
  frequency: number;
}

export class ScanResultWiFi implements IScanResult {
  network: scanWifi[] = [];
  ScanNumber: number;

  constructor() {
    this.ScanNumber = 0;
  }

  addScan(name_: string, signal_: number, encryp_: string, mac_: string, freq: number, scanNumber: number) {
    if (name_.indexOf("hidden") >= 0) {
      name_ = "-";
    }
    this.network.push({
      name: name_,
      signal: signal_,
      encryp: encryp_,
      mac: mac_,
      frequency: freq
    });
    this.ScanNumber = scanNumber;
  }

}

export class wifiResult {
  static parseWifi(atCommand: string, scannum: number) {
    if (atCommand.indexOf("Couldn't get a wifi connection") >= 0 || atCommand.indexOf("no result") >= 0) {
      return undefined;
    }
    atCommand = atCommand.replaceAll(";;;", ";;");
    atCommand = atCommand.replaceAll(";;", ";");
    const command = atCommand.split(";");
    const wifi = new ScanResultWiFi();
    for (let i = 0; i < command.length; i++) {
      if (command[i].indexOf("wifi,more") >= 0) {
        $BTSTracker.value.moreWifi = true;
        return wifi;
      }
      const value = command[i].split(", ");
      if (value[1] != undefined) {
        if (atCommand.indexOf("wifi") < 0) {
          // name, signal, encryption, mac, freq
          wifi.addScan(value[0], parseInt(value[1]), value[3], value[2].toUpperCase(), parseFloat("2.4"), scannum);
        } else if (atCommand.startsWith("wifi")) {
          wifi.addScan(value[2], parseInt(value[3]), value[4], value[1].toUpperCase(), parseFloat(value[5]), scannum);
        }
      }
    }
    return wifi;
  }
}

interface scanBlue {
  name: string;
  signal: number;
  mac: string;
  adv_type_: string,
  remote_add: string;
}

export class ScanResultBluetooth implements IScanResult {
  network: scanBlue[] = [];
  ScanNumber: number;

  constructor() {
    this.ScanNumber = 0;
  }

  addScan(name_: string, signal_: number, mac_: string, adv_type_: string, remote_: string, scanNumber: number) {
    this.network.push({
      name: name_,
      signal: signal_,
      mac: mac_,
      adv_type_: adv_type_,
      remote_add: remote_,
    });
    this.ScanNumber = scanNumber;
  }
}

export class blueResult {
  static parseBlue(atCommand: string, scannum: number) {
    if (atCommand.indexOf("no result") >= 0) {
      return undefined;
    } else {
      atCommand = atCommand.replaceAll(";;;", ";;");
      atCommand = atCommand.replaceAll(";;", ";");
      const command = atCommand.split(";");
      const blue = new ScanResultBluetooth();
      for (let i = 0; i < command.length; i++) {
        if (command[i].indexOf("BT,more") >= 0) {
          $BTSTracker.value.moreBlue = true;
          return blue;
        }
        if (command[i].length > 0 && command[i].startsWith("BT,")) {
          const value = command[i].split(",");
          if (value.length == 6) {
            if (value[4].length == 0) {
              value[4] = "-";
            }
            blue.addScan(value[4], Number(value[5]), value[3].toUpperCase(), value[1], value[2], scannum);
          }
        }
      }
      return blue;
    }
  }
}

export class ScanResultActive {

}


function parseFloatOrZero(input: any) {
  const parsed = parseFloat(input);
  return isNaN(parsed) || parsed === undefined ? 0 : parsed;
}

function parseIntOrZero(input: any) {
  const parsed = parseInt(input);
  return isNaN(parsed) || parsed === undefined ? 0 : parsed;
}

