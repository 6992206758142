import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2095c4c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "connect-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Button = _resolveComponent("Button")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("h1", null, _toDisplayString(_ctx.$t("connecting")), 513), [
      [_vShow, _ctx.btsTracker.connecting]
    ]),
    _withDirectives(_createElementVNode("h1", null, _toDisplayString(_ctx.$t("not_connected")), 513), [
      [_vShow, !_ctx.btsTracker.connecting]
    ]),
    (_ctx.btsTracker.connecting)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tryConnect())),
          type: 'info'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("connect")), 1)
          ]),
          _: 1
        }))
  ], 512)), [
    [_vShow, !_ctx.btsTracker.connected]
  ])
}