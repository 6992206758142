import { Log } from "@/model";
import { Capacitor } from "@capacitor/core";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { createToast } from "mosha-vue-toastify";

export function shareOrDownloadBlob(blob: Blob, fileName: string) {
  if (fileName.endsWith(".scscan")) {
    fileName = fileName.substring(0, fileName.length - 7) + ".csv";
  }
  const file = new File([blob], fileName, { type: blob.type });
  if (navigator.canShare && navigator.canShare({ files: [file] }) && fileName.endsWith(".csv")) {
      Log.info("FileUtils.shareOrDownloadBlob - sharemode fname=" + fileName);
      navigator.share({
          text: fileName,
          title: fileName,
          files: [file]
      } as ShareData).catch(error => {
          Log.error("FileUtils.shareOrDownloadBlob - sharemode error=" + error);
          const a = document.createElement('a');
          a.href = URL.createObjectURL(file);
          a.download = fileName;
          a.click();
          a.remove();
      })


  } else {
  Log.info("navigator.share not supported");
  Log.info("FileUtils.shareOrDownloadBlob - savemode fname=" + fileName);
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
  a.remove();
  }
}

export function downloadBlob (blob: Blob, fileName: string){
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.target = "_blank";
  a.download = fileName;
  a.click();
  a.remove();
  //console.log("ZUPPA")
}


export function downloadFileC(data: string, fileName: string, type: string) {
  if (Capacitor.isNativePlatform()) {
    //console.warn("data len", data.length)
    //console.warn(data)
    Filesystem.writeFile({
      path: "BTSTracker/" + fileName,
      data: data,
      recursive: true,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    }).then((res) => {
      createToast(
        {
          title: "File Saved",
          description: res.uri,
        }, {
        type: 'success',
        showIcon: true,
        hideProgressBar: true,
      }
      );
    }).catch((err) => {
      //console.error("Unable to write file", err);
    });
  } else {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

}

// rfc1924 base 85 uint8array to base 85 string
export function b85encode(data: Uint8Array): string {
  const base85 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!#$%&()*+-;<=>?@^_`{|}~";
  let result = "";
  let value = 0;
  let n = 0;
  for (let i = 0; i < data.length; i++) {
    value = value * 256 + data[i];
    n += 8;
    while (n >= 5) {
      const index = (value >>> (n - 5)) % 85;
      result += base85[index];
      n -= 5;
    }
  }
  if (n > 0) {
    const index = (value << (5 - n)) % 85;
    result += base85[index];
  }
  return result;
}

