import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d24189a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.type, "default"]),
    disabled: _ctx.isDisabled
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode("Button")
    ], true)
  ], 10, _hoisted_1))
}